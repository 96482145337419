import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { instancesPrice } from 'src/app/constants/pricing';
import { UserI } from 'src/app/interfaces/user.interface';
import { ApiSdkService } from 'src/app/services/api-sdk.service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { ResultHandlerService } from 'src/app/services/result-handler.service';
import { phoneExtensions } from 'src/assets/international-phone-extensions/int-phone-extensions';

@Component({
  templateUrl: './user-data.page.html',
  styleUrls: ['./user-data.page.scss']
})
export class UserDataPage {
  userForm!: FormGroup;

  userData: UserI = null as any;

  emailToVerify: string = '';
  secretToVerify: string = '';

  pricesIntances = instancesPrice;

  userCard: any = null;
  
  tooltipVisible: boolean = false;
  tooltipPosition = { top: '0px', left: '0px', width: '0px' };
  searchTerm: string = '';

  phoneExtensions = phoneExtensions; // Your initial large array of phone extensions

  @ViewChild('tooltip', { static: false }) tooltipRef!: ElementRef;
  @ViewChild('triggerInput', { static: false }) triggerInputRef!: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private apiSdkService: ApiSdkService,
    private route: ActivatedRoute,
    private resultHandlerService: ResultHandlerService,
    private alertController: AlertController,
    private mixpanel: MixpanelService
    ) { }

  async ngOnInit() {

    this.mixpanel.createTracking({
      type: 'visit_page',
      place: 'User profile',
    });

    this.userForm = this.formBuilder.group({
      email: [{value:'', disabled: true}],
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      phone: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(15), Validators.pattern('[- +()0-9]+')]],
      country: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(100), this.validateCountryName()]],
      city: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(100)]],
      cp: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(20)]],
      address: ['', [Validators.required,Validators.minLength(5), Validators.maxLength(150)]],
      companyName: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(100)]],
      nif: ['', [Validators.required,Validators.minLength(6), Validators.maxLength(20)]]
    });
    await this.getUserData();
    
    await this.getUserStripeCard();
  }

  get errorControl() {
    return this.userForm.controls;
  }

  
  async getUserStripeCard() {
    const cardsResult = await this.apiSdkService.getUserStripeCard();
    this.userCard = cardsResult.success.data;
  }

  async getUserData() {
    const result = await this.apiSdkService.getFullUserData();
    this.userData = result.success.data;
    this.userForm.patchValue(this.userData);

  }

  async onSubmit() {

    this.mixpanel.createTracking({
      type: 'click',
      place: 'User profile',
      extraText: 'Save data',
    });

    // Trigger form validation for all fields by marking them as touched
    Object.values(this.userForm.controls).forEach(control => {
      control.markAsTouched();
    });

    // Check if the form is valid before proceeding with form submission
    if (this.userForm.valid) {
        await this.apiSdkService.editUserData(this.userForm.value);
        this.resultHandlerService.showToast({message: 'Datos guardados', color: 'success'});
        // if is the first time user is created (name is null), redirect to account page
        window.location.reload();

        /*if(!this.userData?.name) {
          this.router.navigate(['/account']);
          setTimeout(() => {
            window.location.reload();
          }, 100); 
        }*/
    }
  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Cerrar sesión',
      message: '¿Estás seguro que quieres cerrar sesión?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Cerrar sesión',
          handler: () => {
            localStorage.removeItem('token');
            this.router.navigate(['/login']);
            setTimeout(() => {
              window.location.reload();
            }, 20);
          }
        }
      ]
    });
  
    await alert.present();
  }

  async getData(){
    const userResult = await this.apiSdkService.getFullUserData();
    this.userData = userResult.success.data;
    const cardsResult = await this.apiSdkService.getUserStripeCard();
    this.userCard = cardsResult.success.data;
  }

  async uploadCardFinish() {
    //this.changeCard = false;
    await this.getData();
  }

  calculeNextDayToPay(instanceCreatedAt: Date | null | undefined) {
    if (!instanceCreatedAt) return '';

    const currentDate = new Date();
    const nextPaymentDate = new Date(instanceCreatedAt);
    // Set nextPaymentDate to the same year as currentDate
    nextPaymentDate.setFullYear(currentDate.getFullYear());
    nextPaymentDate.setMonth(currentDate.getMonth());

    // If nextPaymentDate is still before currentDate, increment the month
    if (nextPaymentDate <= currentDate) {
      let month = nextPaymentDate.getMonth();

      // Check if it's December
      if (month === 11) {
        month = 0; // Set month to January
        nextPaymentDate.setFullYear(currentDate.getFullYear() + 1); // Increment year by 1
      } else {
        month++;   // Increment month by 1
      }

      nextPaymentDate.setMonth(month);
    }

    return nextPaymentDate;
  }

  onExtensionSelectorClick(e: any) {
    e.stopPropagation(); // Prevent the click from immediately triggering the @HostListener
    this.showTooltip(e);
  }

  showTooltip(event: MouseEvent) {
    this.tooltipVisible = true;
    const targetElement = event.target as HTMLElement;

    const rect = (event.target as HTMLElement).getBoundingClientRect();
    this.tooltipPosition.top = rect.bottom + window.scrollY + 'px';
    this.tooltipPosition.left = rect.left + window.scrollX + 'px';

    if (targetElement.id) {
        const elementById = document.getElementById(targetElement.id);
        if (elementById) {
            this.tooltipPosition.width = elementById.getBoundingClientRect().width + 'px';
        }
    }
  }

  onSelectCountry(country: any) {
    this.userForm.controls['country'].setValue(country.name_es);
    this.tooltipVisible = false;
  }

  setSearchTerm(event: any) {
    this.searchTerm = event.target.value;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.tooltipVisible && !this.tooltipRef.nativeElement.contains(event.target) && !this.triggerInputRef.nativeElement.contains(event.target)) {
      this.tooltipVisible = false;
    }
  }

  validateCountryName(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const valid = this.phoneExtensions.some(ext => ext.name_es === control.value || ext.name_es.toLowerCase() === control.value || ext.name_es.toUpperCase() === control.value);
      return valid ? null : { 'invalidCountryName': { value: control.value } };
    };
  }

}

