import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Stripe, StripeCardElement, loadStripe } from '@stripe/stripe-js';
import { ApiSdkService } from 'src/app/services/api-sdk.service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { PopoverHandlerService } from 'src/app/services/popover-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.scss']
})
export class StripeCardComponent {
  stripe!: Stripe;
  card!: StripeCardElement;
  loading = false;
  @Output() uploadCardFinish = new EventEmitter();

  @Input() noText = false;

  //@ts-ignore
  @ViewChild('cardElement') cardElement: ElementRef;

  constructor(
    private apiSdkService: ApiSdkService,
    private popoverHandler: PopoverHandlerService,
    private mixpanel: MixpanelService
  ) { }

  async ngOnInit() {
      /*const style = {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    };*/
    const style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#aab7c4"
        },
        iconColor: '#6E7A8A', // Example to change the icon color
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    this.stripe = await loadStripe(environment.stripe.publicKey) as Stripe;
    const elements = this.stripe.elements();
    this.card = elements.create('card', {style:style});
    this.card.mount(this.cardElement.nativeElement);
  }

  /*async handleSubmit() {
    const tokenResult = await this.stripe.createToken(this.card);
    console.log("WWWWWWWWWWWWWWWWWWWW")
    console.log(tokenResult);
    if (tokenResult.token) {
      console.log("TOKEN: ",JSON.stringify(tokenResult.token));
      // Send the token backend for processing
      const result = await this.apiSdkService.submitNewStripeCard(tokenResult.token.id);
      console.log("TOTALUM SAVE STRIPE CUSTOMER RESULT: ", result);
      this.uploadCardFinish.emit();
      location.reload();
    } else if (tokenResult.error) {
      // Handle the error on the frontend
      console.log("ERROR AT CREATE CARD TOKEN: ",tokenResult.error);
    }
  }*/

  async handleSubmit() {

    this.mixpanel.createTracking({
      type: 'click',
      place: 'Stripe card',
      extraText: 'Submit card',
      action: {
        status: 'starting'
      }
    });

    this.loading = true;
    const tokenResult = await this.stripe.createToken(this.card);
    if (tokenResult.token) {

        // Send the token to the backend for processing
        const result = await this.apiSdkService.submitNewStripeCard(tokenResult.token.id);
        const resultOneEuroPayment = result.success.data;
        // Check if 3D Secure is required
        if (resultOneEuroPayment.requiresAction) {
            //const resultCardAction = await this.stripe.handleCardAction(resultOneEuroPayment.clientSecret);
            const resultCardAction = await this.stripe.confirmCardPayment(resultOneEuroPayment.clientSecret);
            if (resultCardAction.error) {
                // Handle error after 3D Secure verification
                console.error("3D Secure verification failed:", resultCardAction.error);
                const alertR = await this.popoverHandler.showAlertPopover("Ha habido un error verificando la tarjeta, detalles: "+resultCardAction.error.message);
                this.loading = false;

                this.mixpanel.createTracking({
                  type: 'click',
                  place: 'Stripe card',
                  extraText: 'Submit card',
                  action: {
                    status: 'failed'
                  }
                });

            } else {
                this.loading = false;
                console.log("3D Secure verification succeeded!",resultCardAction);

                this.mixpanel.createTracking({
                  type: 'click',
                  place: 'Stripe card',
                  extraText: 'Submit card',
                  action: {
                    status: 'success'
                  }
                });

                this.uploadCardFinish.emit();
                //location.reload();
            }
        } else {
            // Transaction was successful without 3D Secure
            this.loading = false;
            console.log("Payment succeeded without 3D Secure:", result);

            this.mixpanel.createTracking({
              type: 'click',
              place: 'Stripe card',
              extraText: 'Submit card',
              action: {
                status: 'success'
              }
            });

            this.uploadCardFinish.emit();
            //location.reload();
        }
    } else if (tokenResult.error) {
        // Handle the error on the frontend
        this.loading = false;
        
        this.mixpanel.createTracking({
          type: 'click',
          place: 'Stripe card',
          extraText: 'Submit card',
          action: {
            status: 'failed'
          }
        });

        console.error("ERROR AT CREATE CARD TOKEN:", tokenResult.error);
        const alertR = await this.popoverHandler.showAlertPopover("Ha habido un error verificando la tarjeta, detalles: "+tokenResult.error.message);
    }
  }
}