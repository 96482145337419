import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ResultHandlerService {

  constructor(
    private toastCtrl: ToastController
  ) { }

  async errorHappened(feedbackType: string, config: any) {
    if (feedbackType === 'toast') {
      await this.showToast(config);
    }
  }

  async showToast(config: ToastOptions) {
    config.duration = config?.duration || 5000;
    const toast = await this.toastCtrl.create(config);
    await toast.present();
  }
}
