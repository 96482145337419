export const phoneExtensions = [
  {
    "name_en": "Albania",
    "name_es": "Albania",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Tirana",
    "capital_es": "Tirana",
    "dial_code": "+355",
    "code_2": "AL",
    "code_3": "ALB",
    "tld": ".al",
    "km2": 28748,
    "emoji": "🇦🇱"
  },
  {
    "name_en": "Germany",
    "name_es": "Alemania",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Berlin",
    "capital_es": "Berlín",
    "dial_code": "+49",
    "code_2": "DE",
    "code_3": "DEU",
    "tld": ".de",
    "km2": 357114,
    "emoji": "🇩🇪"
  },
  {
    "name_en": "Andorra",
    "name_es": "Andorra",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Andorra la Vella",
    "capital_es": "Andorra la Vieja",
    "dial_code": "+376",
    "code_2": "AD",
    "code_3": "AND",
    "tld": ".ad",
    "km2": 468,
    "emoji": "🇦🇩"
  },
  {
    "name_en": "Anguilla",
    "name_es": "Anguilla",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "The Valley",
    "capital_es": "El Valle",
    "dial_code": "+1264",
    "code_2": "AI",
    "code_3": "AIA",
    "tld": ".ai",
    "km2": 91,
    "emoji": "🇦🇮"
  },
  {
    "name_en": "Antigua and Barbuda",
    "name_es": "Antigua y Barbuda",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "St. John's",
    "capital_es": "Saint John",
    "dial_code": "+1268",
    "code_2": "AG",
    "code_3": "ATG",
    "tld": ".ag",
    "km2": 442,
    "emoji": "🇦🇬"
  },
  {
    "name_en": "Antarctica",
    "name_es": "Antártida",
    "continent_en": "Antarctica",
    "continent_es": "Antártida",
    "capital_en": "",
    "capital_es": "",
    "dial_code": "+672",
    "code_2": "AQ",
    "code_3": "ATA",
    "tld": ".aq",
    "km2": 14200000,
    "emoji": "🇦🇶"
  },
  {
    "name_en": "Saudi Arabia",
    "name_es": "Arabia Saudita",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Riyadh",
    "capital_es": "Riad",
    "dial_code": "+966",
    "code_2": "SA",
    "code_3": "SAU",
    "tld": ".sa",
    "km2": 2149690,
    "emoji": "🇸🇦"
  },
  {
    "name_en": "Argentina",
    "name_es": "Argentina",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Buenos Aires",
    "capital_es": "Buenos Aires",
    "dial_code": "+54",
    "code_2": "AR",
    "code_3": "ARG",
    "tld": ".ar",
    "km2": 2780400,
    "emoji": "🇦🇷"
  },
  {
    "name_en": "Armenia",
    "name_es": "Armenia",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Yerevan",
    "capital_es": "Ereván",
    "dial_code": "+374",
    "code_2": "AM",
    "code_3": "ARM",
    "tld": ".am",
    "km2": 29743,
    "emoji": "🇦🇲"
  },
  {
    "name_en": "Aruba",
    "name_es": "Aruba",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Oranjestad",
    "capital_es": "Oranjestad",
    "dial_code": "+297",
    "code_2": "AW",
    "code_3": "ABW",
    "tld": ".aw",
    "km2": 193,
    "emoji": "🇦🇼"
  },
  {
    "name_en": "Australia",
    "name_es": "Australia",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Canberra",
    "capital_es": "Canberra",
    "dial_code": "+61",
    "code_2": "AU",
    "code_3": "AUS",
    "tld": ".au",
    "km2": 7692024,
    "emoji": "🇦🇺"
  },
  {
    "name_en": "Austria",
    "name_es": "Austria",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Vienna",
    "capital_es": "Viena",
    "dial_code": "+43",
    "code_2": "AT",
    "code_3": "AUT",
    "tld": ".at",
    "km2": 83871,
    "emoji": "🇦🇹"
  },
  {
    "name_en": "Azerbaijan",
    "name_es": "Azerbaiyán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Baku",
    "capital_es": "Bakú",
    "dial_code": "+994",
    "code_2": "AZ",
    "code_3": "AZE",
    "tld": ".az",
    "km2": 86600,
    "emoji": "🇦🇿"
  },
  {
    "name_en": "Bahamas",
    "name_es": "Bahamas",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Nassau",
    "capital_es": "Nassau",
    "dial_code": "+1242",
    "code_2": "BS",
    "code_3": "BHS",
    "tld": ".bs",
    "km2": 13940,
    "emoji": "🇧🇸"
  },
  {
    "name_en": "Bangladesh",
    "name_es": "Bangladés",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Dhaka",
    "capital_es": "Daca",
    "dial_code": "+880",
    "code_2": "BD",
    "code_3": "BGD",
    "tld": ".bd",
    "km2": 147570,
    "emoji": "🇧🇩"
  },
  {
    "name_en": "Barbados",
    "name_es": "Barbados",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Bridgetown",
    "capital_es": "Bridgetown",
    "dial_code": "+1246",
    "code_2": "BB",
    "code_3": "BRB",
    "tld": ".bb",
    "km2": 430,
    "emoji": "🇧🇧"
  },
  {
    "name_en": "Bahrain",
    "name_es": "Baréin",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Manama",
    "capital_es": "Manama",
    "dial_code": "+973",
    "code_2": "BH",
    "code_3": "BHR",
    "tld": ".bh",
    "km2": 765,
    "emoji": "🇧🇭"
  },
  {
    "name_en": "Belize",
    "name_es": "Belice",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Belmopan",
    "capital_es": "Belmopán",
    "dial_code": "+501",
    "code_2": "BZ",
    "code_3": "BLZ",
    "tld": ".bz",
    "km2": 22966,
    "emoji": "🇧🇿"
  },
  {
    "name_en": "Bermuda",
    "name_es": "Bermudas",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Hamilton",
    "capital_es": "Hamilton",
    "dial_code": "+1441",
    "code_2": "BM",
    "code_3": "BMU",
    "tld": ".bm",
    "km2": 54,
    "emoji": "🇧🇲"
  },
  {
    "name_en": "Belarus",
    "name_es": "Bielorrusia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Minsk",
    "capital_es": "Minsk",
    "dial_code": "+375",
    "code_2": "BY",
    "code_3": "BLR",
    "tld": ".by",
    "km2": 207600,
    "emoji": "🇧🇾"
  },
  {
    "name_en": "Bolivia",
    "name_es": "Bolivia",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Sucre",
    "capital_es": "Sucre",
    "dial_code": "+591",
    "code_2": "BO",
    "code_3": "BOL",
    "tld": ".bo",
    "km2": 1098581,
    "emoji": "🇧🇴"
  },
  {
    "name_en": "Bonaire, Sint Eustatius and Saba",
    "name_es": "Bonaire, San Eustaquio y Saba",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Kralendijk",
    "capital_es": "Kralendijk",
    "dial_code": "+599",
    "code_2": "BQ",
    "code_3": "BES",
    "tld": ".bq",
    "km2": 328,
    "emoji": "🇧🇶"
  },
  {
    "name_en": "Bosnia and Herzegovina",
    "name_es": "Bosnia y Herzegovina",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Sarajevo",
    "capital_es": "Sarajevo",
    "dial_code": "+387",
    "code_2": "BA",
    "code_3": "BIH",
    "tld": ".ba",
    "km2": 51209,
    "emoji": "🇧🇦"
  },
  {
    "name_en": "Brazil",
    "name_es": "Brasil",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Brasilia",
    "capital_es": "Brasilia",
    "dial_code": "+55",
    "code_2": "BR",
    "code_3": "BRA",
    "tld": ".br",
    "km2": 8515767,
    "emoji": "🇧🇷"
  },
  {
    "name_en": "Brunei Darussalam",
    "name_es": "Brunéi",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Bandar Seri Begawan",
    "capital_es": "Bandar Seri Begawan",
    "dial_code": "+673",
    "code_2": "BN",
    "code_3": "BRN",
    "tld": ".bn",
    "km2": 5765,
    "emoji": "🇧🇳"
  },
  {
    "name_en": "Bulgaria",
    "name_es": "Bulgaria",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Sofia",
    "capital_es": "Sofía",
    "dial_code": "+359",
    "code_2": "BG",
    "code_3": "BGR",
    "tld": ".bg",
    "km2": 110879,
    "emoji": "🇧🇬"
  },
  {
    "name_en": "Bhutan",
    "name_es": "Bután",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Thimphu",
    "capital_es": "Timbu",
    "dial_code": "+975",
    "code_2": "BT",
    "code_3": "BTN",
    "tld": ".bt",
    "km2": 38394,
    "emoji": "🇧🇹"
  },
  {
    "name_en": "Belgium",
    "name_es": "Bélgica",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Brussels",
    "capital_es": "Bruselas",
    "dial_code": "+32",
    "code_2": "BE",
    "code_3": "BEL",
    "tld": ".be",
    "km2": 30528,
    "emoji": "🇧🇪"
  },
  {
    "name_en": "Cambodia",
    "name_es": "Camboya",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Phnom Penh",
    "capital_es": "Phnom Penh",
    "dial_code": "+855",
    "code_2": "KH",
    "code_3": "KHM",
    "tld": ".kh",
    "km2": 181035,
    "emoji": "🇰🇭"
  },
  {
    "name_en": "Canada",
    "name_es": "Canadá",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Ottawa",
    "capital_es": "Ottawa",
    "dial_code": "+1",
    "code_2": "CA",
    "code_3": "CAN",
    "tld": ".ca",
    "km2": 9984670,
    "emoji": "🇨🇦"
  },
  {
    "name_en": "Qatar",
    "name_es": "Catar",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Doha",
    "capital_es": "Doha",
    "dial_code": "+974",
    "code_2": "QA",
    "code_3": "QAT",
    "tld": ".qa",
    "km2": 11586,
    "emoji": "🇶🇦"
  },
  {
    "name_en": "Czechia",
    "name_es": "Chequia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Prague",
    "capital_es": "Praga",
    "dial_code": "+420",
    "code_2": "CZ",
    "code_3": "CZE",
    "tld": ".cz",
    "km2": 78865,
    "emoji": "🇨🇿"
  },
  {
    "name_en": "Chile",
    "name_es": "Chile",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Santiago",
    "capital_es": "Santiago",
    "dial_code": "+56",
    "code_2": "CL",
    "code_3": "CHL",
    "tld": ".cl",
    "km2": 756102,
    "emoji": "🇨🇱"
  },
  {
    "name_en": "China",
    "name_es": "China",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Beijing",
    "capital_es": "Pekín",
    "dial_code": "+86",
    "code_2": "CN",
    "code_3": "CHN",
    "tld": ".cn",
    "km2": 9706961,
    "emoji": "🇨🇳"
  },
  {
    "name_en": "Cyprus",
    "name_es": "Chipre",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Nicosia",
    "capital_es": "Nicosia",
    "dial_code": "+357",
    "code_2": "CY",
    "code_3": "CYP",
    "tld": ".cy",
    "km2": 9251,
    "emoji": "🇨🇾"
  },
  {
    "name_en": "Vatican City State",
    "name_es": "Ciudad del Vaticano",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Vatican City",
    "capital_es": "Ciudad del Vaticano",
    "dial_code": "+379",
    "code_2": "VA",
    "code_3": "VAT",
    "tld": ".va",
    "km2": 0.49,
    "emoji": "🇻🇦"
  },
  {
    "name_en": "Colombia",
    "name_es": "Colombia",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Bogota",
    "capital_es": "Bogotá",
    "dial_code": "+57",
    "code_2": "CO",
    "code_3": "COL",
    "tld": ".co",
    "km2": 1141748,
    "emoji": "🇨🇴"
  },
  {
    "name_en": "North Korea",
    "name_es": "Corea del Norte",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Pyongyang",
    "capital_es": "Pyongyang",
    "dial_code": "+850",
    "code_2": "KP",
    "code_3": "PRK",
    "tld": ".kp",
    "km2": 120538,
    "emoji": "🇰🇵"
  },
  {
    "name_en": "South Korea",
    "name_es": "Corea del Sur",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Seoul",
    "capital_es": "Seúl",
    "dial_code": "+82",
    "code_2": "KR",
    "code_3": "KOR",
    "tld": ".kr",
    "km2": 100210,
    "emoji": "🇰🇷"
  },
  {
    "name_en": "Costa Rica",
    "name_es": "Costa Rica",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "San Jose",
    "capital_es": "San José",
    "dial_code": "+506",
    "code_2": "CR",
    "code_3": "CRI",
    "tld": ".cr",
    "km2": 51100,
    "emoji": "🇨🇷"
  },
  {
    "name_en": "Croatia",
    "name_es": "Croacia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Zagreb",
    "capital_es": "Zagreb",
    "dial_code": "+385",
    "code_2": "HR",
    "code_3": "HRV",
    "tld": ".hr",
    "km2": 56594,
    "emoji": "🇭🇷"
  },
  {
    "name_en": "Cuba",
    "name_es": "Cuba",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Havana",
    "capital_es": "La Habana",
    "dial_code": "+53",
    "code_2": "CU",
    "code_3": "CUB",
    "tld": ".cu",
    "km2": 109884,
    "emoji": "🇨🇺"
  },
  {
    "name_en": "Curaçao",
    "name_es": "Curazao",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Willemstad",
    "capital_es": "Willemstad",
    "dial_code": "+599",
    "code_2": "CW",
    "code_3": "CUW",
    "tld": ".cw",
    "km2": 444,
    "emoji": "🇨🇼"
  },
  {
    "name_en": "Denmark",
    "name_es": "Dinamarca",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Copenhagen",
    "capital_es": "Copenhague",
    "dial_code": "+45",
    "code_2": "DK",
    "code_3": "DNK",
    "tld": ".dk",
    "km2": 43094,
    "emoji": "🇩🇰"
  },
  {
    "name_en": "Dominica",
    "name_es": "Dominica",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Roseau",
    "capital_es": "Roseau",
    "dial_code": "+1767",
    "code_2": "DM",
    "code_3": "DMA",
    "tld": ".dm",
    "km2": 751,
    "emoji": "🇩🇲"
  },
  {
    "name_en": "Ecuador",
    "name_es": "Ecuador",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Quito",
    "capital_es": "Quito",
    "dial_code": "+593",
    "code_2": "EC",
    "code_3": "ECU",
    "tld": ".ec",
    "km2": 276841,
    "emoji": "🇪🇨"
  },
  {
    "name_en": "El Salvador",
    "name_es": "El Salvador",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "San Salvador",
    "capital_es": "San Salvador",
    "dial_code": "+503",
    "code_2": "SV",
    "code_3": "SLV",
    "tld": ".sv",
    "km2": 21041,
    "emoji": "🇸🇻"
  },
  {
    "name_en": "United Arab Emirates",
    "name_es": "Emiratos Árabes Unidos",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Abu Dhabi",
    "capital_es": "Abu Dhabi",
    "dial_code": "+971",
    "code_2": "AE",
    "code_3": "ARE",
    "tld": ".ae",
    "km2": 83600,
    "emoji": "🇦🇪"
  },
  {
    "name_en": "Slovakia",
    "name_es": "Eslovaquia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Bratislava",
    "capital_es": "Bratislava",
    "dial_code": "+421",
    "code_2": "SK",
    "code_3": "SVK",
    "tld": ".sk",
    "km2": 49037,
    "emoji": "🇸🇰"
  },
  {
    "name_en": "Slovenia",
    "name_es": "Eslovenia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Ljubljana",
    "capital_es": "Ljubljana",
    "dial_code": "+386",
    "code_2": "SI",
    "code_3": "SVN",
    "tld": ".si",
    "km2": 20273,
    "emoji": "🇸🇮"
  },
  {
    "name_en": "Spain",
    "name_es": "España",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Madrid",
    "capital_es": "Madrid",
    "dial_code": "+34",
    "code_2": "ES",
    "code_3": "ESP",
    "tld": ".es",
    "km2": 505992,
    "emoji": "🇪🇸"
  },
  {
    "name_en": "United States",
    "name_es": "Estados Unidos",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Washington, D.C.",
    "capital_es": "Washington, D.C.",
    "dial_code": "+1",
    "code_2": "US",
    "code_3": "USA",
    "tld": ".us",
    "km2": 9833520,
    "emoji": "🇺🇸"
  },
  {
    "name_en": "Estonia",
    "name_es": "Estonia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Tallinn",
    "capital_es": "Tallin",
    "dial_code": "+372",
    "code_2": "EE",
    "code_3": "EST",
    "tld": ".ee",
    "km2": 45227,
    "emoji": "🇪🇪"
  },
  {
    "name_en": "Philippines",
    "name_es": "Filipinas",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Manila",
    "capital_es": "Manila",
    "dial_code": "+63",
    "code_2": "PH",
    "code_3": "PHL",
    "tld": ".ph",
    "km2": 342353,
    "emoji": "🇵🇭"
  },
  {
    "name_en": "Finland",
    "name_es": "Finlandia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Helsinki",
    "capital_es": "Helsinki",
    "dial_code": "+358",
    "code_2": "FI",
    "code_3": "FIN",
    "tld": ".fi",
    "km2": 338424,
    "emoji": "🇫🇮"
  },
  {
    "name_en": "Fiji",
    "name_es": "Fiyi",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Suva",
    "capital_es": "Suva",
    "dial_code": "+679",
    "code_2": "FJ",
    "code_3": "FJI",
    "tld": ".fj",
    "km2": 18272,
    "emoji": "🇫🇯"
  },
  {
    "name_en": "France",
    "name_es": "Francia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Paris",
    "capital_es": "París",
    "dial_code": "+33",
    "code_2": "FR",
    "code_3": "FRA",
    "tld": ".fr",
    "km2": 640679,
    "emoji": "🇫🇷"
  },
  {
    "name_en": "Georgia",
    "name_es": "Georgia",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Tbilisi",
    "capital_es": "Tiflis",
    "dial_code": "+995",
    "code_2": "GE",
    "code_3": "GEO",
    "tld": ".ge",
    "km2": 69700,
    "emoji": "🇬🇪"
  },
  {
    "name_en": "Gibraltar",
    "name_es": "Gibraltar",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Gibraltar",
    "capital_es": "Gibraltar",
    "dial_code": "+350",
    "code_2": "GI",
    "code_3": "GIB",
    "tld": ".gi",
    "km2": 6,
    "emoji": "🇬🇮"
  },
  {
    "name_en": "Grenada",
    "name_es": "Granada",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "St. George's",
    "capital_es": "St. George's",
    "dial_code": "+1473",
    "code_2": "GD",
    "code_3": "GRD",
    "tld": ".gd",
    "km2": 344,
    "emoji": "🇬🇩"
  },
  {
    "name_en": "Greece",
    "name_es": "Grecia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Athens",
    "capital_es": "Atenas",
    "dial_code": "+30",
    "code_2": "GR",
    "code_3": "GRC",
    "tld": ".gr",
    "km2": 131990,
    "emoji": "🇬🇷"
  },
  {
    "name_en": "Greenland",
    "name_es": "Groenlandia",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Nuuk",
    "capital_es": "Nuuk",
    "dial_code": "+299",
    "code_2": "GL",
    "code_3": "GRL",
    "tld": ".gl",
    "km2": 2166086,
    "emoji": "🇬🇱"
  },
  {
    "name_en": "Guadeloupe",
    "name_es": "Guadalupe",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Basse-Terre",
    "capital_es": "Basse-Terre",
    "dial_code": "+590",
    "code_2": "GP",
    "code_3": "GLP",
    "tld": ".gp",
    "km2": 1628,
    "emoji": "🇬🇵"
  },
  {
    "name_en": "Guam",
    "name_es": "Guam",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Hagåtña",
    "capital_es": "Hagåtña",
    "dial_code": "+1671",
    "code_2": "GU",
    "code_3": "GUM",
    "tld": ".gu",
    "km2": 549,
    "emoji": "🇬🇺"
  },
  {
    "name_en": "Guatemala",
    "name_es": "Guatemala",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Guatemala City",
    "capital_es": "Ciudad de Guatemala",
    "dial_code": "+502",
    "code_2": "GT",
    "code_3": "GTM",
    "tld": ".gt",
    "km2": 108889,
    "emoji": "🇬🇹"
  },
  {
    "name_en": "French Guiana",
    "name_es": "Guayana Francesa",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Cayenne",
    "capital_es": "Cayena",
    "dial_code": "+594",
    "code_2": "GF",
    "code_3": "GUF",
    "tld": ".gf",
    "km2": 83534,
    "emoji": "🇬🇫"
  },
  {
    "name_en": "Guernsey",
    "name_es": "Guernsey",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "St. Peter Port",
    "capital_es": "St. Peter Port",
    "dial_code": "+44",
    "code_2": "GG",
    "code_3": "GGY",
    "tld": ".gg",
    "km2": 78,
    "emoji": "🇬🇬"
  },
  {
    "name_en": "Guyana",
    "name_es": "Guyana",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Georgetown",
    "capital_es": "Georgetown",
    "dial_code": "+592",
    "code_2": "GY",
    "code_3": "GUY",
    "tld": ".gy",
    "km2": 214969,
    "emoji": "🇬🇾"
  },
  {
    "name_en": "Haiti",
    "name_es": "Haití",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Port-au-Prince",
    "capital_es": "Puerto Príncipe",
    "dial_code": "+509",
    "code_2": "HT",
    "code_3": "HTI",
    "tld": ".ht",
    "km2": 27750,
    "emoji": "🇭🇹"
  },
  {
    "name_en": "Honduras",
    "name_es": "Honduras",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Tegucigalpa",
    "capital_es": "Tegucigalpa",
    "dial_code": "+504",
    "code_2": "HN",
    "code_3": "HND",
    "tld": ".hn",
    "km2": 112492,
    "emoji": "🇭🇳"
  },
  {
    "name_en": "Hong Kong",
    "name_es": "Hong Kong",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Hong Kong",
    "capital_es": "Hong Kong",
    "dial_code": "+852",
    "code_2": "HK",
    "code_3": "HKG",
    "tld": ".hk",
    "km2": 2755,
    "emoji": "🇭🇰"
  },
  {
    "name_en": "Hungary",
    "name_es": "Hungría",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Budapest",
    "capital_es": "Budapest",
    "dial_code": "+36",
    "code_2": "HU",
    "code_3": "HUN",
    "tld": ".hu",
    "km2": 93028,
    "emoji": "🇭🇺"
  },
  {
    "name_en": "India",
    "name_es": "India",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "New Delhi",
    "capital_es": "Nueva Delhi",
    "dial_code": "+91",
    "code_2": "IN",
    "code_3": "IND",
    "tld": ".in",
    "km2": 3287590,
    "emoji": "🇮🇳"
  },
  {
    "name_en": "Indonesia",
    "name_es": "Indonesia",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Jakarta",
    "capital_es": "Yakarta",
    "dial_code": "+62",
    "code_2": "ID",
    "code_3": "IDN",
    "tld": ".id",
    "km2": 1904569,
    "emoji": "🇮🇩"
  },
  {
    "name_en": "Iraq",
    "name_es": "Irak",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Baghdad",
    "capital_es": "Bagdad",
    "dial_code": "+964",
    "code_2": "IQ",
    "code_3": "IRQ",
    "tld": ".iq",
    "km2": 438317,
    "emoji": "🇮🇶"
  },
  {
    "name_en": "Ireland",
    "name_es": "Irlanda",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Dublin",
    "capital_es": "Dublín",
    "dial_code": "+353",
    "code_2": "IE",
    "code_3": "IRL",
    "tld": ".ie",
    "km2": 70273,
    "emoji": "🇮🇪"
  },
  {
    "name_en": "Iran",
    "name_es": "Irán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Tehran",
    "capital_es": "Teherán",
    "dial_code": "+98",
    "code_2": "IR",
    "code_3": "IRN",
    "tld": ".ir",
    "km2": 1648195,
    "emoji": "🇮🇷"
  },
  {
    "name_en": "Norfolk Island",
    "name_es": "Isla Norfolk",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Kingston",
    "capital_es": "Kingston",
    "dial_code": "+672",
    "code_2": "NF",
    "code_3": "NFK",
    "tld": ".nf",
    "km2": 36,
    "emoji": "🇳🇫"
  },
  {
    "name_en": "Isle of Man",
    "name_es": "Isla de Man",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Douglas",
    "capital_es": "Douglas",
    "dial_code": "+44",
    "code_2": "IM",
    "code_3": "IMN",
    "tld": ".im",
    "km2": 572,
    "emoji": "🇮🇲"
  },
  {
    "name_en": "Christmas Island",
    "name_es": "Isla de Navidad",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Flying Fish Cove",
    "capital_es": "Flying Fish Cove",
    "dial_code": "+61",
    "code_2": "CX",
    "code_3": "CXR",
    "tld": ".cx",
    "km2": 135,
    "emoji": "🇨🇽"
  },
  {
    "name_en": "Iceland",
    "name_es": "Islandia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Reykjavik",
    "capital_es": "Reykjavik",
    "dial_code": "+354",
    "code_2": "IS",
    "code_3": "ISL",
    "tld": ".is",
    "km2": 103000,
    "emoji": "🇮🇸"
  },
  {
    "name_en": "Cayman Islands",
    "name_es": "Islas Caimán",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "George Town",
    "capital_es": "George Town",
    "dial_code": "+1345",
    "code_2": "KY",
    "code_3": "CYM",
    "tld": ".ky",
    "km2": 264,
    "emoji": "🇰🇾"
  },
  {
    "name_en": "Cocos (Keeling) Islands",
    "name_es": "Islas Cocos",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "West Island",
    "capital_es": "West Island",
    "dial_code": "+61",
    "code_2": "CC",
    "code_3": "CCK",
    "tld": ".cc",
    "km2": 14,
    "emoji": "🇨🇨"
  },
  {
    "name_en": "Cook Islands",
    "name_es": "Islas Cook",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Avarua",
    "capital_es": "Avarua",
    "dial_code": "+682",
    "code_2": "CK",
    "code_3": "COK",
    "tld": ".ck",
    "km2": 236,
    "emoji": "🇨🇰"
  },
  {
    "name_en": "Faroe Islands",
    "name_es": "Islas Feroe",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Tórshavn",
    "capital_es": "Tórshavn",
    "dial_code": "+298",
    "code_2": "FO",
    "code_3": "FRO",
    "tld": ".fo",
    "km2": 1393,
    "emoji": "🇫🇴"
  },
  {
    "name_en": "South Georgia and the South Sandwich Islands",
    "name_es": "Islas Georgias del Sur y Sandwich del Sur",
    "continent_en": "Antarctica",
    "continent_es": "Antártida",
    "capital_en": "King Edward Point",
    "capital_es": "King Edward Point",
    "dial_code": "+500",
    "code_2": "GS",
    "code_3": "SGS",
    "tld": ".gs",
    "km2": 3903,
    "emoji": "🇬🇸"
  },
  {
    "name_en": "Heard Island and McDonald Islands",
    "name_es": "Islas Heard y McDonald",
    "continent_en": "Antarctica",
    "continent_es": "Antártida",
    "capital_en": "",
    "capital_es": "",
    "dial_code": "+672",
    "code_2": "HM",
    "code_3": "HMD",
    "tld": ".hm",
    "km2": 412,
    "emoji": "🇭🇲"
  },
  {
    "name_en": "Falkland Islands (Malvinas)",
    "name_es": "Islas Malvinas",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Stanley",
    "capital_es": "Stanley",
    "dial_code": "+500",
    "code_2": "FK",
    "code_3": "FLK",
    "tld": ".fk",
    "km2": 12173,
    "emoji": "🇫🇰"
  },
  {
    "name_en": "Northern Mariana Islands",
    "name_es": "Islas Marianas del Norte",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Saipan",
    "capital_es": "Saipan",
    "dial_code": "+1670",
    "code_2": "MP",
    "code_3": "MNP",
    "tld": ".mp",
    "km2": 464,
    "emoji": "🇲🇵"
  },
  {
    "name_en": "Marshall Islands",
    "name_es": "Islas Marshall",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Majuro",
    "capital_es": "Majuro",
    "dial_code": "+692",
    "code_2": "MH",
    "code_3": "MHL",
    "tld": ".mh",
    "km2": 181,
    "emoji": "🇲🇭"
  },
  {
    "name_en": "Pitcairn",
    "name_es": "Islas Pitcairn",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Adamstown",
    "capital_es": "Adamstown",
    "dial_code": "+64",
    "code_2": "PN",
    "code_3": "PCN",
    "tld": ".pn",
    "km2": 47,
    "emoji": "🇵🇳"
  },
  {
    "name_en": "Solomon Islands",
    "name_es": "Islas Salomón",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Honiara",
    "capital_es": "Honiara",
    "dial_code": "+677",
    "code_2": "SB",
    "code_3": "SLB",
    "tld": ".sb",
    "km2": 28896,
    "emoji": "🇸🇧"
  },
  {
    "name_en": "Turks and Caicos Islands",
    "name_es": "Islas Turcas y Caicos",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Cockburn Town",
    "capital_es": "Cockburn Town",
    "dial_code": "+1649",
    "code_2": "TC",
    "code_3": "TCA",
    "tld": ".tc",
    "km2": 948,
    "emoji": "🇹🇨"
  },
  {
    "name_en": "Virgin Islands, British",
    "name_es": "Islas Vírgenes Británicas",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Road Town",
    "capital_es": "Road Town",
    "dial_code": "+1284",
    "code_2": "VG",
    "code_3": "VGB",
    "tld": ".vg",
    "km2": 151,
    "emoji": "🇻🇬"
  },
  {
    "name_en": "Virgin Islands, U.S.",
    "name_es": "Islas Vírgenes de los Estados Unidos",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Charlotte Amalie",
    "capital_es": "Charlotte Amalie",
    "dial_code": "+1340",
    "code_2": "VI",
    "code_3": "VIR",
    "tld": ".vi",
    "km2": 347,
    "emoji": "🇻🇮"
  },
  {
    "name_en": "Israel",
    "name_es": "Israel",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Jerusalem",
    "capital_es": "Jerusalén",
    "dial_code": "+972",
    "code_2": "IL",
    "code_3": "ISR",
    "tld": ".il",
    "km2": 20770,
    "emoji": "🇮🇱"
  },
  {
    "name_en": "Italy",
    "name_es": "Italia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Rome",
    "capital_es": "Roma",
    "dial_code": "+39",
    "code_2": "IT",
    "code_3": "ITA",
    "tld": ".it",
    "km2": 301336,
    "emoji": "🇮🇹"
  },
  {
    "name_en": "Jamaica",
    "name_es": "Jamaica",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Kingston",
    "capital_es": "Kingston",
    "dial_code": "+1 876",
    "code_2": "JM",
    "code_3": "JAM",
    "tld": ".jm",
    "km2": 10991,
    "emoji": "🇯🇲"
  },
  {
    "name_en": "Japan",
    "name_es": "Japón",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Tokyo",
    "capital_es": "Tokio",
    "dial_code": "+81",
    "code_2": "JP",
    "code_3": "JPN",
    "tld": ".jp",
    "km2": 377930,
    "emoji": "🇯🇵"
  },
  {
    "name_en": "Jersey",
    "name_es": "Jersey",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Saint Helier",
    "capital_es": "Saint Helier",
    "dial_code": "+44",
    "code_2": "JE",
    "code_3": "JEY",
    "tld": ".je",
    "km2": 116,
    "emoji": "🇯🇪"
  },
  {
    "name_en": "Jordan",
    "name_es": "Jordania",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Amman",
    "capital_es": "Amán",
    "dial_code": "+962",
    "code_2": "JO",
    "code_3": "JOR",
    "tld": ".jo",
    "km2": 89342,
    "emoji": "🇯🇴"
  },
  {
    "name_en": "Kazakhstan",
    "name_es": "Kazajistán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Astana",
    "capital_es": "Astana",
    "dial_code": "+7 7",
    "code_2": "KZ",
    "code_3": "KAZ",
    "tld": ".kz",
    "km2": 2724900,
    "emoji": "🇰🇿"
  },
  {
    "name_en": "Kyrgyzstan",
    "name_es": "Kirguistán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Bishkek",
    "capital_es": "Bishkek",
    "dial_code": "+996",
    "code_2": "KG",
    "code_3": "KGZ",
    "tld": ".kg",
    "km2": 199951,
    "emoji": "🇰🇬"
  },
  {
    "name_en": "Kiribati",
    "name_es": "Kiribati",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "South Tarawa",
    "capital_es": "Sur Tarawa",
    "dial_code": "+686",
    "code_2": "KI",
    "code_3": "KIR",
    "tld": ".ki",
    "km2": 811,
    "emoji": "🇰🇮"
  },
  {
    "name_en": "Kosovo",
    "name_es": "Kosovo",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Pristina",
    "capital_es": "Pristina",
    "dial_code": "+383",
    "code_2": "XK",
    "code_3": "XKX",
    "tld": ".xk",
    "km2": 10887,
    "emoji": "🇽🇰"
  },
  {
    "name_en": "Kuwait",
    "name_es": "Kuwait",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Kuwait City",
    "capital_es": "Kuwait City",
    "dial_code": "+965",
    "code_2": "KW",
    "code_3": "KWT",
    "tld": ".kw",
    "km2": 17818,
    "emoji": "🇰🇼"
  },
  {
    "name_en": "Laos",
    "name_es": "Laos",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Vientiane",
    "capital_es": "Vientiane",
    "dial_code": "+856",
    "code_2": "LA",
    "code_3": "LAO",
    "tld": ".la",
    "km2": 236800,
    "emoji": "🇱🇦"
  },
  {
    "name_en": "Latvia",
    "name_es": "Letonia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Riga",
    "capital_es": "Riga",
    "dial_code": "+371",
    "code_2": "LV",
    "code_3": "LVA",
    "tld": ".lv",
    "km2": 64559,
    "emoji": "🇱🇻"
  },
  {
    "name_en": "Liechtenstein",
    "name_es": "Liechtenstein",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Vaduz",
    "capital_es": "Vaduz",
    "dial_code": "+423",
    "code_2": "LI",
    "code_3": "LIE",
    "tld": ".li",
    "km2": 160,
    "emoji": "🇱🇮"
  },
  {
    "name_en": "Lithuania",
    "name_es": "Lituania",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Vilnius",
    "capital_es": "Vilna",
    "dial_code": "+370",
    "code_2": "LT",
    "code_3": "LTU",
    "tld": ".lt",
    "km2": 65300,
    "emoji": "🇱🇹"
  },
  {
    "name_en": "Luxembourg",
    "name_es": "Luxemburgo",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Luxembourg",
    "capital_es": "Luxemburgo",
    "dial_code": "+352",
    "code_2": "LU",
    "code_3": "LUX",
    "tld": ".lu",
    "km2": 2586,
    "emoji": "🇱🇺"
  },
  {
    "name_en": "Lebanon",
    "name_es": "Líbano",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Beirut",
    "capital_es": "Beirut",
    "dial_code": "+961",
    "code_2": "LB",
    "code_3": "LBN",
    "tld": ".lb",
    "km2": 10452,
    "emoji": "🇱🇧"
  },
  {
    "name_en": "Macao",
    "name_es": "Macao",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Macao",
    "capital_es": "Macao",
    "dial_code": "+853",
    "code_2": "MO",
    "code_3": "MAC",
    "tld": ".mo",
    "km2": 30,
    "emoji": "🇲🇴"
  },
  {
    "name_en": "North Macedonia",
    "name_es": "Macedonia del Norte",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Skopje",
    "capital_es": "Skopje",
    "dial_code": "+389",
    "code_2": "MK",
    "code_3": "MKD",
    "tld": ".mk",
    "km2": 25713,
    "emoji": "🇲🇰"
  },
  {
    "name_en": "Malaysia",
    "name_es": "Malasia",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Kuala Lumpur",
    "capital_es": "Kuala Lumpur",
    "dial_code": "+60",
    "code_2": "MY",
    "code_3": "MYS",
    "tld": ".my",
    "km2": 330803,
    "emoji": "🇲🇾"
  },
  {
    "name_en": "Maldives",
    "name_es": "Maldivas",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Malé",
    "capital_es": "Malé",
    "dial_code": "+960",
    "code_2": "MV",
    "code_3": "MDV",
    "tld": ".mv",
    "km2": 300,
    "emoji": "🇲🇻"
  },
  {
    "name_en": "Malta",
    "name_es": "Malta",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Valletta",
    "capital_es": "La Valeta",
    "dial_code": "+356",
    "code_2": "MT",
    "code_3": "MLT",
    "tld": ".mt",
    "km2": 316,
    "emoji": "🇲🇹"
  },
  {
    "name_en": "Martinique",
    "name_es": "Martinica",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Fort-de-France",
    "capital_es": "Fort-de-France",
    "dial_code": "+596",
    "code_2": "MQ",
    "code_3": "MTQ",
    "tld": ".mq",
    "km2": 1128,
    "emoji": "🇲🇶"
  },
  {
    "name_en": "Micronesia",
    "name_es": "Micronesia",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Palikir",
    "capital_es": "Palikir",
    "dial_code": "+691",
    "code_2": "FM",
    "code_3": "FSM",
    "tld": ".fm",
    "km2": 702,
    "emoji": "🇫🇲"
  },
  {
    "name_en": "Moldova",
    "name_es": "Moldavia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Chisinau",
    "capital_es": "Chisinau",
    "dial_code": "+373",
    "code_2": "MD",
    "code_3": "MDA",
    "tld": ".md",
    "km2": 33846,
    "emoji": "🇲🇩"
  },
  {
    "name_en": "Mongolia",
    "name_es": "Mongolia",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Ulan Bator",
    "capital_es": "Ulan Bator",
    "dial_code": "+976",
    "code_2": "MN",
    "code_3": "MNG",
    "tld": ".mn",
    "km2": 1564110,
    "emoji": "🇲🇳"
  },
  {
    "name_en": "Montenegro",
    "name_es": "Montenegro",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Podgorica",
    "capital_es": "Podgorica",
    "dial_code": "+382",
    "code_2": "ME",
    "code_3": "MNE",
    "tld": ".me",
    "km2": 13812,
    "emoji": "🇲🇪"
  },
  {
    "name_en": "Montserrat",
    "name_es": "Montserrat",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Plymouth",
    "capital_es": "Plymouth",
    "dial_code": "+1664",
    "code_2": "MS",
    "code_3": "MSR",
    "tld": ".ms",
    "km2": 102,
    "emoji": "🇲🇸"
  },
  {
    "name_en": "Myanmar",
    "name_es": "Myanmar",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Naypyidaw",
    "capital_es": "Naypyidaw",
    "dial_code": "+95",
    "code_2": "MM",
    "code_3": "MMR",
    "tld": ".mm",
    "km2": 676578,
    "emoji": "🇲🇲"
  },
  {
    "name_en": "Mexico",
    "name_es": "México",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Mexico City",
    "capital_es": "Ciudad de México",
    "dial_code": "+52",
    "code_2": "MX",
    "code_3": "MEX",
    "tld": ".mx",
    "km2": 1964375,
    "emoji": "🇲🇽"
  },
  {
    "name_en": "Monaco",
    "name_es": "Mónaco",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Monaco",
    "capital_es": "Mónaco",
    "dial_code": "+377",
    "code_2": "MC",
    "code_3": "MCO",
    "tld": ".mc",
    "km2": 2,
    "emoji": "🇲🇨"
  },
  {
    "name_en": "Nauru",
    "name_es": "Nauru",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Yaren",
    "capital_es": "Yaren",
    "dial_code": "+674",
    "code_2": "NR",
    "code_3": "NRU",
    "tld": ".nr",
    "km2": 21,
    "emoji": "🇳🇷"
  },
  {
    "name_en": "Nepal",
    "name_es": "Nepal",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Kathmandu",
    "capital_es": "Kathmandu",
    "dial_code": "+977",
    "code_2": "NP",
    "code_3": "NPL",
    "tld": ".np",
    "km2": 147181,
    "emoji": "🇳🇵"
  },
  {
    "name_en": "Nicaragua",
    "name_es": "Nicaragua",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Managua",
    "capital_es": "Managua",
    "dial_code": "+505",
    "code_2": "NI",
    "code_3": "NIC",
    "tld": ".ni",
    "km2": 130373,
    "emoji": "🇳🇮"
  },
  {
    "name_en": "Niue",
    "name_es": "Niue",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Alofi",
    "capital_es": "Alofi",
    "dial_code": "+683",
    "code_2": "NU",
    "code_3": "NIU",
    "tld": ".nu",
    "km2": 260,
    "emoji": "🇳🇺"
  },
  {
    "name_en": "Norway",
    "name_es": "Noruega",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Oslo",
    "capital_es": "Oslo",
    "dial_code": "+47",
    "code_2": "NO",
    "code_3": "NOR",
    "tld": ".no",
    "km2": 323802,
    "emoji": "🇳🇴"
  },
  {
    "name_en": "New Caledonia",
    "name_es": "Nueva Caledonia",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Noumea",
    "capital_es": "Noumea",
    "dial_code": "+687",
    "code_2": "NC",
    "code_3": "NCL",
    "tld": ".nc",
    "km2": 18575,
    "emoji": "🇳🇨"
  },
  {
    "name_en": "New Zealand",
    "name_es": "Nueva Zelanda",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Wellington",
    "capital_es": "Wellington",
    "dial_code": "+64",
    "code_2": "NZ",
    "code_3": "NZL",
    "tld": ".nz",
    "km2": 270467,
    "emoji": "🇳🇿"
  },
  {
    "name_en": "Oman",
    "name_es": "Omán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Muscat",
    "capital_es": "Mascate",
    "dial_code": "+968",
    "code_2": "OM",
    "code_3": "OMN",
    "tld": ".om",
    "km2": 309500,
    "emoji": "🇴🇲"
  },
  {
    "name_en": "Pakistan",
    "name_es": "Pakistán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Islamabad",
    "capital_es": "Islamabad",
    "dial_code": "+92",
    "code_2": "PK",
    "code_3": "PAK",
    "tld": ".pk",
    "km2": 881912,
    "emoji": "🇵🇰"
  },
  {
    "name_en": "Palau",
    "name_es": "Palaos",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Ngerulmud",
    "capital_es": "Ngerulmud",
    "dial_code": "+680",
    "code_2": "PW",
    "code_3": "PLW",
    "tld": ".pw",
    "km2": 459,
    "emoji": "🇵🇼"
  },
  {
    "name_en": "Palestine",
    "name_es": "Palestina",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Ramallah",
    "capital_es": "Ramala",
    "dial_code": "+970",
    "code_2": "PS",
    "code_3": "PSE",
    "tld": ".ps",
    "km2": 6020,
    "emoji": "🇵🇸"
  },
  {
    "name_en": "Panama",
    "name_es": "Panamá",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Panama City",
    "capital_es": "Ciudad de Panamá",
    "dial_code": "+507",
    "code_2": "PA",
    "code_3": "PAN",
    "tld": ".pa",
    "km2": 75417,
    "emoji": "🇵🇦"
  },
  {
    "name_en": "Papua New Guinea",
    "name_es": "Papúa Nueva Guinea",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Port Moresby",
    "capital_es": "Puerto Moresby",
    "dial_code": "+675",
    "code_2": "PG",
    "code_3": "PNG",
    "tld": ".pg",
    "km2": 462840,
    "emoji": "🇵🇬"
  },
  {
    "name_en": "Paraguay",
    "name_es": "Paraguay",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Asunción",
    "capital_es": "Asunción",
    "dial_code": "+595",
    "code_2": "PY",
    "code_3": "PRY",
    "tld": ".py",
    "km2": 406752,
    "emoji": "🇵🇾"
  },
  {
    "name_en": "Netherlands",
    "name_es": "Países Bajos",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Amsterdam",
    "capital_es": "Ámsterdam",
    "dial_code": "+31",
    "code_2": "NL",
    "code_3": "NLD",
    "tld": ".nl",
    "km2": 41850,
    "emoji": "🇳🇱"
  },
  {
    "name_en": "Peru",
    "name_es": "Perú",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Lima",
    "capital_es": "Lima",
    "dial_code": "+51",
    "code_2": "PE",
    "code_3": "PER",
    "tld": ".pe",
    "km2": 1285216,
    "emoji": "🇵🇪"
  },
  {
    "name_en": "French Polynesia",
    "name_es": "Polinesia Francesa",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Papeete",
    "capital_es": "Papeete",
    "dial_code": "+689",
    "code_2": "PF",
    "code_3": "PYF",
    "tld": ".pf",
    "km2": 4167,
    "emoji": "🇵🇫"
  },
  {
    "name_en": "Poland",
    "name_es": "Polonia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Warsaw",
    "capital_es": "Varsovia",
    "dial_code": "+48",
    "code_2": "PL",
    "code_3": "POL",
    "tld": ".pl",
    "km2": 312679,
    "emoji": "🇵🇱"
  },
  {
    "name_en": "Portugal",
    "name_es": "Portugal",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Lisbon",
    "capital_es": "Lisboa",
    "dial_code": "+351",
    "code_2": "PT",
    "code_3": "PRT",
    "tld": ".pt",
    "km2": 92090,
    "emoji": "🇵🇹"
  },
  {
    "name_en": "Puerto Rico",
    "name_es": "Puerto Rico",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "San Juan",
    "capital_es": "San Juan",
    "dial_code": "+1939",
    "code_2": "PR",
    "code_3": "PRI",
    "tld": ".pr",
    "km2": 8870,
    "emoji": "🇵🇷"
  },
  {
    "name_en": "United Kingdom",
    "name_es": "Reino Unido",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "London",
    "capital_es": "Londres",
    "dial_code": "+44",
    "code_2": "GB",
    "code_3": "GBR",
    "tld": ".uk",
    "km2": 242900,
    "emoji": "🇬🇧"
  },
  {
    "name_en": "Dominican Republic",
    "name_es": "República Dominicana",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Santo Domingo",
    "capital_es": "Santo Domingo",
    "dial_code": "+1849",
    "code_2": "DO",
    "code_3": "DOM",
    "tld": ".do",
    "km2": 48671,
    "emoji": "🇩🇴"
  },
  {
    "name_en": "Romania",
    "name_es": "Rumania",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Bucharest",
    "capital_es": "Bucarest",
    "dial_code": "+40",
    "code_2": "RO",
    "code_3": "ROU",
    "tld": ".ro",
    "km2": 238391,
    "emoji": "🇷🇴"
  },
  {
    "name_en": "Russia",
    "name_es": "Rusia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Moscow",
    "capital_es": "Moscú",
    "dial_code": "+7",
    "code_2": "RU",
    "code_3": "RUS",
    "tld": ".ru",
    "km2": 17098242,
    "emoji": "🇷🇺"
  },
  {
    "name_en": "Samoa",
    "name_es": "Samoa",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Apia",
    "capital_es": "Apia",
    "dial_code": "+685",
    "code_2": "WS",
    "code_3": "WSM",
    "tld": ".ws",
    "km2": 2842,
    "emoji": "🇼🇸"
  },
  {
    "name_en": "American Samoa",
    "name_es": "Samoa Americana",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Pago Pago",
    "capital_es": "Pago Pago",
    "dial_code": "+1684",
    "code_2": "AS",
    "code_3": "ASM",
    "tld": ".as",
    "km2": 199,
    "emoji": "🇦🇸"
  },
  {
    "name_en": "Saint Barthélemy",
    "name_es": "San Bartolomé",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Gustavia",
    "capital_es": "Gustavia",
    "dial_code": "+590",
    "code_2": "BL",
    "code_3": "BLM",
    "tld": ".bl",
    "km2": 21,
    "emoji": "🇧🇱"
  },
  {
    "name_en": "Saint Kitts and Nevis",
    "name_es": "San Cristóbal y Nieves",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Basseterre",
    "capital_es": "Basseterre",
    "dial_code": "+1869",
    "code_2": "KN",
    "code_3": "KNA",
    "tld": ".kn",
    "km2": 261,
    "emoji": "🇰🇳"
  },
  {
    "name_en": "San Marino",
    "name_es": "San Marino",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "San Marino",
    "capital_es": "San Marino",
    "dial_code": "+378",
    "code_2": "SM",
    "code_3": "SMR",
    "tld": ".sm",
    "km2": 61,
    "emoji": "🇸🇲"
  },
  {
    "name_en": "Saint Martin",
    "name_es": "San Martín",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Marigot",
    "capital_es": "Marigot",
    "dial_code": "+590",
    "code_2": "MF",
    "code_3": "MAF",
    "tld": ".mf",
    "km2": 53,
    "emoji": "🇲🇫"
  },
  {
    "name_en": "Saint Pierre and Miquelon",
    "name_es": "San Pedro y Miquelón",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Saint-Pierre",
    "capital_es": "Saint-Pierre",
    "dial_code": "+508",
    "code_2": "PM",
    "code_3": "SPM",
    "tld": ".pm",
    "km2": 242,
    "emoji": "🇵🇲"
  },
  {
    "name_en": "Saint Vincent and the Grenadines",
    "name_es": "San Vicente y las Granadinas",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Kingstown",
    "capital_es": "Kingstown",
    "dial_code": "+1784",
    "code_2": "VC",
    "code_3": "VCT",
    "tld": ".vc",
    "km2": 389,
    "emoji": "🇻🇨"
  },
  {
    "name_en": "Saint Lucia",
    "name_es": "Santa Lucía",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Castries",
    "capital_es": "Castries",
    "dial_code": "+1758",
    "code_2": "LC",
    "code_3": "LCA",
    "tld": ".lc",
    "km2": 616,
    "emoji": "🇱🇨"
  },
  {
    "name_en": "Serbia",
    "name_es": "Serbia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Belgrade",
    "capital_es": "Belgrado",
    "dial_code": "+381",
    "code_2": "RS",
    "code_3": "SRB",
    "tld": ".rs",
    "km2": 88361,
    "emoji": "🇷🇸"
  },
  {
    "name_en": "Singapore",
    "name_es": "Singapur",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Singapore",
    "capital_es": "Singapur",
    "dial_code": "+65",
    "code_2": "SG",
    "code_3": "SGP",
    "tld": ".sg",
    "km2": 710,
    "emoji": "🇸🇬"
  },
  {
    "name_en": "Sint Maarten",
    "name_es": "Sint Maarten",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Philipsburg",
    "capital_es": "Philipsburg",
    "dial_code": "+1721",
    "code_2": "SX",
    "code_3": "SXM",
    "tld": ".sx",
    "km2": 34,
    "emoji": "🇸🇽"
  },
  {
    "name_en": "Syria",
    "name_es": "Siria",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Damascus",
    "capital_es": "Damasco",
    "dial_code": "+963",
    "code_2": "SY",
    "code_3": "SYR",
    "tld": ".sy",
    "km2": 185180,
    "emoji": "🇸🇾"
  },
  {
    "name_en": "Sri Lanka",
    "name_es": "Sri Lanka",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Colombo",
    "capital_es": "Colombo",
    "dial_code": "+94",
    "code_2": "LK",
    "code_3": "LKA",
    "tld": ".lk",
    "km2": 65610,
    "emoji": "🇱🇰"
  },
  {
    "name_en": "Sweden",
    "name_es": "Suecia",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Stockholm",
    "capital_es": "Estocolmo",
    "dial_code": "+46",
    "code_2": "SE",
    "code_3": "SWE",
    "tld": ".se",
    "km2": 450295,
    "emoji": "🇸🇪"
  },
  {
    "name_en": "Switzerland",
    "name_es": "Suiza",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Bern",
    "capital_es": "Berna",
    "dial_code": "+41",
    "code_2": "CH",
    "code_3": "CHE",
    "tld": ".ch",
    "km2": 41284,
    "emoji": "🇨🇭"
  },
  {
    "name_en": "Suriname",
    "name_es": "Surinam",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Paramaribo",
    "capital_es": "Paramaribo",
    "dial_code": "+597",
    "code_2": "SR",
    "code_3": "SUR",
    "tld": ".sr",
    "km2": 163820,
    "emoji": "🇸🇷"
  },
  {
    "name_en": "Svalbard and Jan Mayen",
    "name_es": "Svalbard y Jan Mayen",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Longyearbyen",
    "capital_es": "Longyearbyen",
    "dial_code": "+47",
    "code_2": "SJ",
    "code_3": "SJM",
    "tld": ".sj",
    "km2": 62422,
    "emoji": "🇸🇯"
  },
  {
    "name_en": "Thailand",
    "name_es": "Tailandia",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Bangkok",
    "capital_es": "Bangkok",
    "dial_code": "+66",
    "code_2": "TH",
    "code_3": "THA",
    "tld": ".th",
    "km2": 513120,
    "emoji": "🇹🇭"
  },
  {
    "name_en": "Taiwan",
    "name_es": "Taiwán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Taipei",
    "capital_es": "Taipei",
    "dial_code": "+886",
    "code_2": "TW",
    "code_3": "TWN",
    "tld": ".tw",
    "km2": 36193,
    "emoji": "🇹🇼"
  },
  {
    "name_en": "Tajikistan",
    "name_es": "Tayikistán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Dushanbe",
    "capital_es": "Dusambé",
    "dial_code": "+992",
    "code_2": "TJ",
    "code_3": "TJK",
    "tld": ".tj",
    "km2": 143100,
    "emoji": "🇹🇯"
  },
  {
    "name_en": "British Indian Ocean Territory",
    "name_es": "Territorio Británico del Océano Índico",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Diego Garcia",
    "capital_es": "Diego García",
    "dial_code": "+246",
    "code_2": "IO",
    "code_3": "IOT",
    "tld": ".io",
    "km2": 60,
    "emoji": "🇮🇴"
  },
  {
    "name_en": "French Southern Territories",
    "name_es": "Territorios Australes Franceses",
    "continent_en": "Antarctica",
    "continent_es": "Antártida",
    "capital_en": "Port-aux-Français",
    "capital_es": "Port-aux-Français",
    "dial_code": "+262",
    "code_2": "TF",
    "code_3": "ATF",
    "tld": ".tf",
    "km2": 7747,
    "emoji": "🇹🇫"
  },
  {
    "name_en": "Timor-Leste",
    "name_es": "Timor-Leste",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Dili",
    "capital_es": "Dili",
    "dial_code": "+670",
    "code_2": "TL",
    "code_3": "TLS",
    "tld": ".tl",
    "km2": 14874,
    "emoji": "🇹🇱"
  },
  {
    "name_en": "Tokelau",
    "name_es": "Tokelau",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Fakaofo",
    "capital_es": "Fakaofo",
    "dial_code": "+690",
    "code_2": "TK",
    "code_3": "TKL",
    "tld": ".tk",
    "km2": 12,
    "emoji": "🇹🇰"
  },
  {
    "name_en": "Tonga",
    "name_es": "Tonga",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Nuku'alofa",
    "capital_es": "Nuku'alofa",
    "dial_code": "+676",
    "code_2": "TO",
    "code_3": "TON",
    "tld": ".to",
    "km2": 747,
    "emoji": "🇹🇴"
  },
  {
    "name_en": "Trinidad and Tobago",
    "name_es": "Trinidad y Tobago",
    "continent_en": "North America",
    "continent_es": "América del Norte",
    "capital_en": "Port of Spain",
    "capital_es": "Puerto España",
    "dial_code": "+1868",
    "code_2": "TT",
    "code_3": "TTO",
    "tld": ".tt",
    "km2": 5130,
    "emoji": "🇹🇹"
  },
  {
    "name_en": "Turkmenistan",
    "name_es": "Turkmenistán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Ashgabat",
    "capital_es": "Ashgabat",
    "dial_code": "+993",
    "code_2": "TM",
    "code_3": "TKM",
    "tld": ".tm",
    "km2": 488100,
    "emoji": "🇹🇲"
  },
  {
    "name_en": "Türkiye",
    "name_es": "Turquía",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Ankara",
    "capital_es": "Ankara",
    "dial_code": "+90",
    "code_2": "TR",
    "code_3": "TUR",
    "tld": ".tr",
    "km2": 783562,
    "emoji": "🇹🇷"
  },
  {
    "name_en": "Tuvalu",
    "name_es": "Tuvalu",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Funafuti",
    "capital_es": "Funafuti",
    "dial_code": "+688",
    "code_2": "TV",
    "code_3": "TUV",
    "tld": ".tv",
    "km2": 26,
    "emoji": "🇹🇻"
  },
  {
    "name_en": "Ukraine",
    "name_es": "Ucrania",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Kiev",
    "capital_es": "Kiev",
    "dial_code": "+380",
    "code_2": "UA",
    "code_3": "UKR",
    "tld": ".ua",
    "km2": 603500,
    "emoji": "🇺🇦"
  },
  {
    "name_en": "Uruguay",
    "name_es": "Uruguay",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Montevideo",
    "capital_es": "Montevideo",
    "dial_code": "+598",
    "code_2": "UY",
    "code_3": "URY",
    "tld": ".uy",
    "km2": 181034,
    "emoji": "🇺🇾"
  },
  {
    "name_en": "Uzbekistan",
    "name_es": "Uzbekistán",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Tashkent",
    "capital_es": "Tashkent",
    "dial_code": "+998",
    "code_2": "UZ",
    "code_3": "UZB",
    "tld": ".uz",
    "km2": 447400,
    "emoji": "🇺🇿"
  },
  {
    "name_en": "Vanuatu",
    "name_es": "Vanuatu",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Port Vila",
    "capital_es": "Port Vila",
    "dial_code": "+678",
    "code_2": "VU",
    "code_3": "VUT",
    "tld": ".vu",
    "km2": 12189,
    "emoji": "🇻🇺"
  },
  {
    "name_en": "Venezuela",
    "name_es": "Venezuela",
    "continent_en": "South America",
    "continent_es": "América del Sur",
    "capital_en": "Caracas",
    "capital_es": "Caracas",
    "dial_code": "+58",
    "code_2": "VE",
    "code_3": "VEN",
    "tld": ".ve",
    "km2": 916445,
    "emoji": "🇻🇪"
  },
  {
    "name_en": "Vietnam",
    "name_es": "Vietnam",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Hanoi",
    "capital_es": "Hanoi",
    "dial_code": "+84",
    "code_2": "VN",
    "code_3": "VNM",
    "tld": ".vn",
    "km2": 331212,
    "emoji": "🇻🇳"
  },
  {
    "name_en": "Wallis and Futuna",
    "name_es": "Wallis y Futuna",
    "continent_en": "Oceania",
    "continent_es": "Oceanía",
    "capital_en": "Mata-Utu",
    "capital_es": "Mata-Utu",
    "dial_code": "+681",
    "code_2": "WF",
    "code_3": "WLF",
    "tld": ".wf",
    "km2": 142,
    "emoji": "🇼🇫"
  },
  {
    "name_en": "Yemen",
    "name_es": "Yemen",
    "continent_en": "Asia",
    "continent_es": "Asia",
    "capital_en": "Sana'a",
    "capital_es": "Sana'a",
    "dial_code": "+967",
    "code_2": "YE",
    "code_3": "YEM",
    "tld": ".ye",
    "km2": 527968,
    "emoji": "🇾🇪"
  },
  {
    "name_en": "Åland Islands",
    "name_es": "Åland",
    "continent_en": "Europe",
    "continent_es": "Europa",
    "capital_en": "Mariehamn",
    "capital_es": "Mariehamn",
    "dial_code": "+358",
    "code_2": "AX",
    "code_3": "ALA",
    "tld": ".ax",
    "km2": 1580,
    "emoji": "🇦🇽"
  }
]