import { Component } from '@angular/core';
import { instancesPrice, serversPrice } from 'src/app/constants/pricing';
import { UserI } from 'src/app/interfaces/user.interface';
import { ApiSdkService } from 'src/app/services/api-sdk.service';

@Component({
  templateUrl: './billing.page.html',
  styleUrls: ['./billing.page.scss']
})
export class BillingPage {

  user!: UserI;
  userCard: any = null;
  pricesIntances = instancesPrice
  pricesServers = serversPrice;

  constructor(
    private apiSdkService: ApiSdkService
  ) { }

  async ngOnInit() {
    await this.getUserStripeCard();
    await this.getUserData();
  }

  async getUserData() {
    const result = await this.apiSdkService.getFullUserData();
    this.user = result.success.data;
  }

  async getUserServers() {
    // get user servers from this.user.totalumServers

  }

  async getUserStripeCard() {
    const cardsResult = await this.apiSdkService.getUserStripeCard();
    this.userCard = cardsResult.success.data;
  }


  calculeNextDayToPay(instanceCreatedAt: Date | null | undefined) {
    if (!instanceCreatedAt) return '';

    const currentDate = new Date();
    const nextPaymentDate = new Date(instanceCreatedAt);
    // Set nextPaymentDate to the same year as currentDate
    nextPaymentDate.setFullYear(currentDate.getFullYear());
    nextPaymentDate.setMonth(currentDate.getMonth());

    // If nextPaymentDate is still before currentDate, increment the month
    if (nextPaymentDate <= currentDate) {
      let month = nextPaymentDate.getMonth();

      // Check if it's December
      if (month === 11) {
        month = 0; // Set month to January
        nextPaymentDate.setFullYear(currentDate.getFullYear() + 1); // Increment year by 1
      } else {
        month++;   // Increment month by 1
      }

      nextPaymentDate.setMonth(month);
    }

    return nextPaymentDate;
  }

  async getData(){
    const userResult = await this.apiSdkService.getFullUserData();
    this.user = userResult.success.data;
    const cardsResult = await this.apiSdkService.getUserStripeCard();
    this.userCard = cardsResult.success.data;
  }

  async uploadCardFinish() {
    //this.changeCard = false;
    await this.getData();
  }


}
