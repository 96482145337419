<ion-app>

  <div class="ds-accounts-header" *ngIf="userData?.email">
    <div class="ds-accounts-header-left">
      <div class="ds-accounts-header-left-totalumlogo" >
        <img src="assets/totalum-logo-recortado.png" alt="Logo">
      </div>
      <div class="ds-accounts-header-left-totalumname"  *ngIf="!smallScreen && userData?.totalumInstances?.length == 1">
        {{userData?.totalumInstances?.[0]?.organizationId}}
      </div>
      <div class="ds-accounts-header-left-plan"  *ngIf="!smallScreen && userData?.totalumInstances?.length == 1">
        <div class="ds-chip">Plan&nbsp;<span class="ds-chip-plan"> {{userData?.totalumInstances?.[0]?.plan}}</span>
        </div>
      </div>
    </div>
    <div class="ds-accounts-header-right">
      <div class="ds-accounts-header-right-navigation-buttons">
        <ng-container *ngIf="userData?.totalumInstances?.length! > 1">
          <div class="ds-accounts-header-right-navigation-button" routerLink="/account" [routerLinkActive]="'ds-accounts-header-right-navigation-button-active'">
            Proyectos
          </div>
          <div class="ds-accounts-header-right-navigation-button" routerLink="/billing" [routerLinkActive]="'ds-accounts-header-right-navigation-button-active'">
            Facturación
          </div>
        </ng-container>

        <ng-container *ngIf="userData?.totalumInstances?.length === 1">
          <div class="ds-accounts-header-right-navigation-button" routerLink="/account" [routerLinkActive]="'ds-accounts-header-right-navigation-button-active'">
            Crear otro proyecto
          </div>
        </ng-container>
        
        <div class="ds-accounts-header-right-navigation-button" *ngIf="userData?.totalumInstances?.length == 1" (click)="openTotalumInstance(userData?.totalumInstances?.[0]!)">
          Entrar a tu Totalum
        </div>
        
        <div class="ds-accounts-header-right-navigation-button" routerLink="/select-plan" [routerLinkActive]="'ds-accounts-header-right-navigation-button-active'">
          Planes
        </div>
        <div class="ds-accounts-header-right-navigation-button" routerLink="/user-data" [routerLinkActive]="'ds-accounts-header-right-navigation-button-active'">
          Perfil
        </div>
      </div>
      <div class="ds-accounts-header-right-userinfo">
        <ion-icon name="person-circle"></ion-icon>
        <span>{{ userData?.name }}</span>
      </div>
    </div>
  </div>

  <!--<ion-header class="app-header" *ngIf="userData?.name">
    <ion-toolbar>

      <ion-title *ngIf="!smallScreen">
        <img src="../assets/totalum-logo-blue-text.png" alt="Logo" style="height:30px; vertical-align: middle;">
      </ion-title>

      <ion-buttons slot="end" style="margin-right: 5%;">
        <ion-button routerLink="/account"><ion-icon name="desktop-outline"></ion-icon>&nbsp; Proyectos</ion-button>
        <ion-button routerLink="/billing"><ion-icon name="stats-chart-outline"></ion-icon>&nbsp; Facturación</ion-button>
        <ion-button routerLink="/user-data"><ion-icon name="person-outline"></ion-icon>&nbsp; Tu perfil</ion-button>
      </ion-buttons>

    </ion-toolbar>
  </ion-header>-->

  <ion-router-outlet></ion-router-outlet>

</ion-app>
