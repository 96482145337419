import { PopoverHandlerService } from 'src/app/services/popover-handler.service';
import { PopoverController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiSdkService } from './services/api-sdk.service';
import { UserI } from './interfaces/user.interface';
import { TotalumInstanceI } from './interfaces/instance.interface';
import { environment } from 'src/environments/environment';
import { MixpanelService } from './services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userData: UserI = null as any;
  smallScreen: boolean = false;
  constructor(
    private router: Router,
    private apiSdkService: ApiSdkService,
    private popoverHandlerService: PopoverHandlerService,
    private mixpanelService: MixpanelService
  ) {}

  async ngOnInit() {
    this.mixpanelService.init("65369ba08ffd157ee0b6d0119af92cfc");

    this.detectSmallScreen();
    await this.getUserData();
  }

  async getUserData() {
    const result = await this.apiSdkService.getFullUserData(true);
    this.userData = result?.success?.data;
  }

  detectSmallScreen() {
    /*if (window.innerWidth <= 1000) { 
      this.popoverHandlerService.showAlertPopover('Por favor, conectase desde un dispositivo con una pantalla más grande.');
    }*/

    if (window.innerWidth <= 700) { 
      this.smallScreen = true;
    }
  }

  async openTotalumInstance(instance: TotalumInstanceI) {
    const organizationId = instance.organizationId;
    /*const data = localStorage.getItem(organizationId) as string;
    const decryptedDataString = await this.utilsService.decrypt(data, organizationId);
    //console.log('decryptedDataString',decryptedDataString)
    const result = await this.apiSdkService.crypt(decryptedDataString);
    const cryptedDataString = result.success.data;*/
    const cryptedDataString = instance.defaultUser as string;
    const cryptedDataStringEncoded = encodeURIComponent(cryptedDataString);
    const urlToOpen = `${environment.totalumFrontendUrl}auth/login?auth=${cryptedDataStringEncoded}&organizationId=${organizationId}`;
    window.location.href = urlToOpen;
  }
  
}
