import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
})
export class PopoverMenuComponent implements OnInit {
  @Input() type: 'server' | 'instance' = 'instance';


  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    
  }

  // on events

  onClosePopover(data?: any) {
    this.popoverCtrl.dismiss(data);
  }

  onEdit() {
    this.onClosePopover('edit');
  }

  onDelete() {
    this.alertController.create({
      header: 'Borrar',
      message: '¿Estás seguro que lo quieres borrar? Esta opción no se puede deshacer.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Borrar',
          handler: () => {
            this.onClosePopover('delete');
          }
        }
      ]
    }).then(alert => alert.present());
  }

  onSelectCreateInstance() {
    this.onClosePopover('create-instance');
  }

}
