import { Component, Input } from '@angular/core';
import { ApiSdkService } from 'src/app/services/api-sdk.service';

@Component({
  selector: 'app-stripe-subscription',
  templateUrl: './stripe-subscription.component.html',
  styleUrls: ['./stripe-subscription.component.scss']
})
export class StripeSubscriptionComponent {
  changeCard = false;
  user: any=null;
  userCard: any = null;
  @Input() noText = false;

  constructor(
    private apiSdkService: ApiSdkService
  ) { }
  
  async ngOnInit() {
    this.getData();
  }

  async getData(){
    const userResult = await this.apiSdkService.getFullUserData();
    this.user = userResult.success.data;
    const cardsResult = await this.apiSdkService.getUserStripeCard();
    this.userCard = cardsResult.success.data;
  }

  async cancelSubscription() {
    /*const result = await this.apiSdkService.cancelUserSubscription();
    console.log(result);
    await this.getData();
    location.reload();*/
  }

  async uploadCardFinish() {
    this.changeCard = false;
    await this.getData();
  }

}
