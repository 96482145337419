
<div class="subscription">
    <div class="ds-accounts-body-box-title" *ngIf="!noText">
        Tarjeta vinculada. Ya puedes contratar el plan.
    </div>
    <br *ngIf="!noText">
    <div class="subscription-card">
        <div style="display: flex;">
            <div>
                <div class="subscription-card-title">
                    Tarjeta de {{userCard?.funding === 'credit' ? 'Crédito' : 'Débito' }}
                </div>
                <div class="subscription-card-subtitle">
                    {{userCard?.brand}} **** **** **** {{userCard?.last4}}
                </div>
            </div>
            <div>
                <div *ngIf="userCard?.brand=== 'Visa'">
                    <img src="../../../assets/visa.svg" alt="" style="margin-left: 15px;width: 70px;">
                </div>
                <div *ngIf="userCard?.brand=== 'MasterCard'">
                    <img src="../../../assets/mastercard.png" alt="" style="margin-left: 15px;width: 70px;">
                    
                </div>

            </div>
        </div>
        <br>
        <div *ngIf="!changeCard">
            <ion-button color="danger" fill="outline" size="small" (click)="changeCard=true">Cambiar tarjeta</ion-button>
        </div>
    </div>
    <div class="subscription-changecard" *ngIf="changeCard">
        <div>Introduce los datos de la nueva tarjeta</div>
        <ion-button fill="outline" size="small" (click)="changeCard=false">Volver atrás </ion-button>
        <app-stripe-card (uploadCardFinish)="uploadCardFinish()"></app-stripe-card>
    </div>

    <!--<div>
        <ion-label color="danger" class="small-label" (click)="cancelSubscription()">Eliminar Tarjeta</ion-label>
    </div>-->

</div>
<!--<br><br><br><br>
<div style="width: 100%;">
    <div class="ds-accounts-body-box-title">
        Tarjeta vinculada
    </div>

    <div class="subscription-card">
        <div style="display: flex;">
            <div>
                <div class="subscription-card-title">
                    Tarjeta de {{userCard?.funding === 'credit' ? 'Crédito' : 'Débito' }}
                </div>
                <div class="subscription-card-subtitle">
                    {{userCard?.brand}} **** **** **** {{userCard?.last4}}
                </div>
            </div>
            <div>
                <div *ngIf="userCard?.brand=== 'Visa'">
                    <img src="../../../assets/visa.svg" alt="" style="margin-left: 15px;width: 70px;">
                </div>
                <div *ngIf="userCard?.brand=== 'MasterCard'">
                    <img src="../../../assets/mastercard.png" alt="" style="margin-left: 15px;width: 70px;">
                </div>
            </div>
        </div>
    </div>

    <div class="ds-accounts-body-box-form-button">
        <!~~<button class="ds-button-secondary" type="submit" (click)="logout()">Cambiar tarjeta</button>~~>
        <div></div>
        <!~~<button class="ds-button-primary" type="submit" (click)="onSubmit()">Guardar datos</button>~~>
    </div>

</div>-->