<ion-content style="--padding-top: 56px;">
    <div *ngIf="serverCreating" style="position:fixed;width: 100%;padding: 16px;background-color: rgb(131, 131, 255);text-align: center; z-index: 1000000000; display: flex; align-items: center;justify-content: center;">
        Creando el servidor {{serverCreating.name}}, por favor espera de 5 a 8 minutos...
        <ion-spinner style="margin-left: 20px;width: 18px;padding-top: 20px;"></ion-spinner>
    </div>
    <div *ngIf="loading"
        style="position:fixed;width: 100%;height: 100%;background-color: rgba(240, 248, 255, 0.685);display: flex;justify-content: center;align-items: center;z-index: 100000;">
        <ion-spinner></ion-spinner>
    </div>
    
    <div class="instances">
        <div style="font-size: 30px;font-weight: bold;margin-bottom: 15px; margin-top: 16px;">Todos tus proyectos Totalum</div>

        <div class="b-instances-list">
            <div class="instances-list-header">
                <div class="instances-list-header-title">Proyectos gratuitos</div>
                <div class="instances-list-header-button">
                    <ion-button class="button-primary" (click)="openCreateInstanceModal({plan:'free'})" mode="ios">Crear proyecto gratis</ion-button>
                </div>
            </div>
            
            <div class="instances-list-content">
                
                <ng-container *ngIf="!freeInstances || freeInstances?.length === 0">
                    <span>
                        Aún no tienes ningún proyecto Totalum gratuito creado, haz click en el botón "Crear nuevo" para empezar.
                    </span>
                </ng-container>

                <div class="instances-list-content-instances" *ngIf="freeInstances && freeInstances.length > 0">
                    <app-instance-box   *ngFor="let instance of freeInstances"
                    (onOpenInstance)="openTotalumInstance(instance)" [instance]="instance"
                    (onInteractInstance)="onInteractInstance($event, instance)"></app-instance-box>
                </div>
            </div>
        </div>

        <div style="position: relative;">

            <div class="no-pay-overlay" *ngIf="(userData && !userData?.stripeClientId)">
                <div>Para poder continuar  a un plan superior <br> necesitas introducir una tarjeta válida</div>
                <br>
                <ion-button (click)="goToBilling()" class="button-success" mode="ios">Añadir método de pago</ion-button>
            </div>
        <div  [ngStyle]="(userData && !userData?.stripeClientId) ? {'filter': 'blur(3px)', 'pointer-events': 'none', 'user-select': 'none'} : {}">
            
        <div class="b-instances-list">
            <div class="instances-list-header">
                <div class="instances-list-header-title">Proyectos básicos</div>
                <div class="instances-list-header-button">
                    <ion-button class="button-primary" (click)="openCreateInstanceModal({plan:'shared'})" mode="ios">Crear proyecto básico</ion-button>
                </div>
            </div>

            
            <div class="instances-list-content">
                <ng-container *ngIf="!sharedInstances || sharedInstances?.length === 0">
                    <span>
                        Aún no tienes ningún proyecto Totalum básico creado, haz click en el botón "Crear nuevo" para empezar.
                    </span>
                </ng-container>

                <div class="instances-list-content-instances" *ngIf="sharedInstances && sharedInstances.length > 0">
                    <app-instance-box   *ngFor="let instance of sharedInstances"
                    (onOpenInstance)="openTotalumInstance(instance)" [instance]="instance"
                    (onInteractInstance)="onInteractInstance($event,instance)"></app-instance-box>
                </div>
            </div>
        </div>

        <div class="b-instances-list">
            <div class="instances-list-header">
                <div class="instances-list-header-title">Proyectos Profesionales</div>
                <div class="instances-list-header-button">
                    <ion-button class="button-primary" (click)="openCreateInstanceModal({plan:'profesional'})" mode="ios">Crear proyecto profesional</ion-button>
                </div>
            </div>

            
            <div class="instances-list-content">
                <ng-container *ngIf="!profesionalInstances || profesionalInstances?.length === 0">
                    <span>
                        Aún no tienes ningún proyecto Totalum profesional creado, haz click en el botón "Crear nuevo" para empezar.
                    </span>
                </ng-container>

                <div class="instances-list-content-instances" *ngIf="profesionalInstances && profesionalInstances.length > 0">
                    <app-instance-box   *ngFor="let instance of profesionalInstances"
                    (onOpenInstance)="openTotalumInstance(instance)" [instance]="instance"
                    (onInteractInstance)="onInteractInstance($event,instance)"></app-instance-box>
                </div>
            </div>
        </div>

        <div class="b-instances-list">
            <div class="instances-list-header">
                <div class="instances-list-header-title">Servidores dedicados</div>
                <div class="instances-list-header-button">
                    <ion-button class="button-tertiary" (click)="openCreateServerModal()" mode="ios">Crear servidor</ion-button>
                </div>
            </div>

            <div class="instances-list-content">
                <ng-container *ngIf="!servers || servers?.length === 0">
                    <span>
                        Aún no tienes ningún servidor dedicado, haz click en el botón "Crear nuevo" para empezar.
                    </span>
                </ng-container>

                <div class="server" *ngFor="let server of servers">
                    <div class="instances-list-header">
                        <div class="instances-list-header-title">Servidor {{server?.name}}</div>
                        <div class="instances-list-header-button">
                            <ion-button (click)="openCreateInstanceModal({serverName: server?.name})" class="button-primary"  mode="ios">Crear proyecto en este servidor</ion-button>
                            <ion-button mode="ios" class="button-secondary" (click)="onShowServerPopoverMenu($event, server)">
                                <ion-icon slot="start" name="create-outline"></ion-icon>
                                Editar
                            </ion-button>
                        </div>
                    </div>
                    <div class="server-content">
                        <ng-container *ngIf="!server?.totalumInstancesPupulated || server.totalumInstancesPupulated?.length === 0">
                            <span>
                                Aún no tienes ningún proyecto Totalum en este servidor. Dale a "Crear" para empezar.
                            </span>
                        </ng-container>

                        <ng-container *ngIf="$any(server?.totalumInstancesPupulated)?.length > 0">
                            <app-instance-box *ngFor="let instance of server?.totalumInstancesPupulated"
                            (onOpenInstance)="openTotalumInstance(instance)"
                            (onInteractInstance)="onInteractInstance($event, instance, server.name)"
                            [instance]="instance"
                            
                            ></app-instance-box>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </div>
        <br><br><br><br><br>

        <!--<section class="free-instances">
            <div style="color: rgb(37, 37, 37);font-size: 20px;font-weight: bold;margin-bottom: 20px;">
                Proyectos gratuitos &nbsp;&nbsp;<i (click)="openCreateInstanceModal({plan:'free'})"
                    class="fa-solid fa-plus clickable-card"></i>
            </div>
            <div *ngIf="!freeInstances || freeInstances?.length === 0">
                <div style="color: rgb(100, 100, 100);">
                    Aún no tienes ningún proyecto gratuito creado.
                </div>
                <br>
            </div>
            <div *ngIf="freeInstances && freeInstances.length > 0">
                <app-instance-columns></app-instance-columns>
                <app-instance-card *ngFor="let instance of freeInstances"
                    (onOpenInstance)="openTotalumInstance(instance)" [instance]="instance"
                    (onInteractInstance)="onInteractInstance($event, instance)"></app-instance-card>
            </div>

        </section>

        <div>
            <div *ngIf="userData && !userData.stripeClientId">
                <br>
                <span style="background-color: rgb(209, 235, 250);margin-bottom: 30px;font-size: 18px;padding:6px;border: solid 1px rgb(206, 206, 206);border-radius: 5px;">
                    Para usar Totalum en proyectos de pago, tendrás que vincular tu tarjeta
                </span>
                <br><br><br>
                <ion-button style="width: 200px;margin-top: 100px;position: absolute;z-index: 1000;margin-left: 3%;" (click)="goToBilling()">Añadir tarjeta</ion-button>
            </div>
            <div [ngStyle]="(userData && !userData.stripeClientId) ? {'filter': 'blur(2px)'} : {}">
                <div *ngIf="userData && !userData.stripeClientId" style="position: absolute;width: 100%;height: 100%;"></div>
                <section class="shared-instances">
                    <div style="color: rgb(37, 37, 37);font-size: 20px;font-weight: bold;margin-bottom: 20px;">
                        Proyectos básicos &nbsp;&nbsp;<i (click)="openCreateInstanceModal({plan:'shared'})"
                            class="fa-solid fa-plus clickable-card"></i>
                    </div>
                    <div *ngIf="!sharedInstances || sharedInstances?.length === 0">
                        <div style="color: rgb(100, 100, 100);">
                            Aún no tienes ningún proyecto básico creado.
                        </div>
                        <br>
                    </div>
                    <div *ngIf="sharedInstances && sharedInstances.length > 0">
                        <app-instance-columns></app-instance-columns>
                        <app-instance-card *ngFor="let instance of sharedInstances"
                            (onOpenInstance)="openTotalumInstance(instance)" [instance]="instance"
                            (onInteractInstance)="onInteractInstance($event,instance)"></app-instance-card>
                    </div>

                </section>
                <br><br>
                <section class="server-instances">
                    <div style="color: rgb(37, 37, 37);font-size: 20px;font-weight: bold;margin-bottom: 20px;">
                        Servidores dedicados
                    </div>
                    <div *ngIf="!servers || servers?.length === 0">
                        <div style="color: rgb(100, 100, 100);">
                            Aún no tienes ningún servidor dedicado.
                        </div>
                        <br>
                    </div>
                    <div>
                        <div class="instance-server" *ngFor="let server of servers">
                            <div style="padding: 20px;font-size: 19px;">
                                <i class="fa-solid fa-server"></i> Servidor: {{server.name}} &nbsp;&nbsp;<i (click)="onShowServerPopoverMenu($event, server)"
                                    class="fa-solid fa-ellipsis-vertical clickable-card"></i>
                            </div>
                            <div *ngIf="!server?.totalumInstancesPupulated || server.totalumInstancesPupulated?.length === 0">
                                <div style="color: rgb(100, 100, 100);">
                                    Aún no tienes ningún proyecto creado en este servidor.
                                </div>
                                <br>
                            </div>
                            <div *ngIf="$any(server?.totalumInstancesPupulated)?.length > 0">
                                <app-instance-columns></app-instance-columns>
                                <app-instance-card *ngFor="let instance of server?.totalumInstancesPupulated"
                                    (onOpenInstance)="openTotalumInstance(instance)"
                                    (onInteractInstance)="onInteractInstance($event, instance, server.name)"
                                    [instance]="instance"></app-instance-card>
                                <div class="instances-new clickable-card"
                                (click)="openCreateInstanceModal({serverName: server.name})">
                                <ion-icon name="add-outline" class="add-icon"></ion-icon>
                                <span>Crear nuevo proyecto dentro del servidor</span>
                            </div>
                            </div>
                        </div>

                        <div class="instances-new clickable-card-server" (click)="openCreateServerModal()" >
                            <ion-icon name="add-outline" class="add-icon"></ion-icon>
                            <span>Crear nuevo servidor</span>
                        </div>

                    </div>

                </section>
                <br><br><br><br><br><br>
        </div>-->
    </div>
</ion-content>