import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TotalumInstanceI } from 'src/app/interfaces/instance.interface';
import { PopoverMenuComponent } from '../../popover-menu/popover-menu.component';

@Component({
  selector: 'app-instance-box',
  templateUrl: './instance-box.component.html',
  styleUrls: ['./instance-box.component.scss'],
})
export class InstanceBoxComponent  implements OnInit {
  @Input() instance!: TotalumInstanceI;
  @Output() onOpenInstance = new EventEmitter<TotalumInstanceI>();
  @Output() onInteractInstance = new EventEmitter<'edit' | 'delete'>();
  constructor(
    private popoverCtrl: PopoverController
  ) { }

  async ngOnInit() {
  }

  openTotalumInstance(instance: TotalumInstanceI) {
    this.onOpenInstance.emit(instance);
  }

  async onShowPopoverMenu(event: any, popoverType: string) {
    const popover = await this.popoverCtrl.create({
      component: PopoverMenuComponent,
      componentProps: {
        popoverType: popoverType
      },
      event: event
    });
    await popover.present();

    const result = (await popover.onDidDismiss()).data;

    console.log('EL RESULT', result);
    if (result === 'delete') {
      this.onInteractInstance.emit('delete');
    }

    if (result === 'edit') {
      this.onInteractInstance.emit('edit');
    }
  }
  
}
