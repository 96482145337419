

export const instancesPrice = {
    free: 0,
    shared: 49.95,
    profesional: 149.95,
    enterprise: 299.95,
}

export const serversPrice = {
    v1: 79.95
}
