import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { phoneExtensions } from 'src/assets/international-phone-extensions/int-phone-extensions';

@Component({
  selector: 'app-modal-country-selector',
  templateUrl: './modal-country-selector.component.html',
  styleUrls: ['./modal-country-selector.component.scss'],
})
export class ModalCountrySelectorComponent  implements OnInit, OnChanges {
  @Input() searchTerm: string = '';
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  phoneExtensions = phoneExtensions; // Your initial large array of phone extensions
  displayedPhoneExtensions: any = [];
  private batchSize = 30; // How many items to load each time

  constructor(
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchTerm'] && !changes['searchTerm'].firstChange) {
      // searchTerm input has changed, and it's not the first change (initial assignment)
      this.onSearchChange(this.searchTerm);
    }
  }

  ngOnInit() {
    this.initDisplayList();
  }

  initDisplayList() {
    // Initially load the first batch of items
    this.displayedPhoneExtensions = this.phoneExtensions.slice(0, this.batchSize);
  }

  onSearchChange(searchTerm: any) {
    if (!searchTerm) {
      this.initDisplayList();
    } else {
      const normalizedSearchTerm = searchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      this.displayedPhoneExtensions = this.phoneExtensions.filter((phone: any) =>
        phone.name_es.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(normalizedSearchTerm) ||
        phone.dial_code.includes(searchTerm) // Assuming dial codes don't have accents
      );
    }
  }

  loadMore(event: any) {
    const len = this.displayedPhoneExtensions.length;
    const more = this.phoneExtensions.slice(len, len + this.batchSize);
    this.displayedPhoneExtensions = [
      ...this.displayedPhoneExtensions,
      ...more,
    ];
    event.target.complete();

    // Disable infinite scroll if the entire list has been loaded
    if (this.displayedPhoneExtensions.length === this.phoneExtensions.length) {
      event.target.disabled = true;
    }
  }

  onSelectPhone(phone: any) {
    phone.name_es = phone.name_es.toLowerCase();
    this.onSelect.emit(phone);
  }
}
