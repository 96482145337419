<div style="width: 100%;">
    <ng-container *ngIf="!noText">
        <h4>Registra tarjeta a Totalum</h4>
        <p>Al registrar la tarjeta, se te cobrará 1€ para verificarla y se te devolverá una vez verificada.</p>
    </ng-container>

    <div #cardElement id="cardElement"></div>
    <br>
    <br>
    <div class="ds-accounts-body-box-form-button">
        <div></div>
        <button [disabled]="loading" class="ds-button-primary" (click)="handleSubmit()">
            <ng-container *ngIf="!loading">
                Registrar tarjeta
              </ng-container>

              <ng-container *ngIf="loading">
                <ion-spinner name="crescent"></ion-spinner>
                Registrando
              </ng-container>
            
        </button>

    </div>
</div>
