export const environment = {
  production: true,
  apiDomain: 'https://api-accounts.totalum.app/',	
  totalumFrontendUrl: 'https://web.totalum.app/',
  recaptcha: {
    siteKey: '6LdBZyUoAAAAAPPgxPznOK-B8jAYje5qw6sJHxao'
  },
  stripe: {
    publicKey: 'pk_live_51Nlv9TCufOAhj8AieOjZk6EO8U60l8aeaXH47kr86xNG0oC4qvbDyd3b3V4O3qDNhhmRbCl4L0ZtDQu8TNa4aIuZ00edRNvs87'
  }
};
