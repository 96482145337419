import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { serversPrice } from 'src/app/constants/pricing';
import { PlansI } from 'src/app/interfaces/instance.interface';
import { TotalumServerI } from 'src/app/interfaces/server.interface';
import { UserI } from 'src/app/interfaces/user.interface';
import { ApiSdkService } from 'src/app/services/api-sdk.service';
import { ResultHandlerService } from 'src/app/services/result-handler.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-create-server',
  templateUrl: './create-server.component.html',
  styleUrls: ['./create-server.component.scss']
})
export class CreateServerComponent {
  @Input() server?:TotalumServerI
  serverForm!: FormGroup;
  pricesServers = serversPrice;
  isLoading = false;
  edit = false;

  @Input() user!: UserI

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private apiSdkService: ApiSdkService,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private resultHandlerService: ResultHandlerService,
    private utilsService:UtilsService,
    private recaptchaV3Service: ReCaptchaV3Service,

    ) { }

  async ngOnInit() {
    this.serverForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(35), Validators.pattern('^[a-z]([a-z0-9]|-(?!-))*(?<!-)$')]],
      description: ['', [Validators.maxLength(200)]],
    });

    if (this.server) {
      this.edit = true;
      this.serverForm.controls['name'].disable();
      console.log("this.server",this.server);
      this.serverForm.patchValue(this.server);
      return;
    };

  }

  toLowercase(event: any) {
    let inputValue = event.target.value;
    if (inputValue && typeof inputValue === 'string') {
      
      inputValue = inputValue.toLowerCase().replace(/\s+/g, '-');

      this.serverForm.get('name')!.setValue(inputValue);
    }
  }

  get errorControl() {
    return this.serverForm.controls;
  }

  async onEditServer() {
    // Trigger form validation for all fields by marking them as touched
    Object.values(this.serverForm.controls).forEach(control => {
      control.markAsTouched();
    });

    // Check if the form is valid before proceeding with form submission
    if (this.serverForm.valid) {
      const recaptchaToken = await this.recaptchaV3Service.execute('editServer').toPromise();

        this.isLoading = true;
        this.resultHandlerService.showToast({
          message: 'Editando servidor...',
          color: 'primary'
        });
        let result;
        // add the _id
        this.serverForm.value._id = this.server?._id;
        result = await this.apiSdkService.editServer(this.serverForm.value, recaptchaToken as string);

        this.isLoading = false;
        if (result.error) {
          this.resultHandlerService.errorHappened('toast', {
            message: result.error.errors,
            color: 'danger'
          });
          return;

        }
        this.modalCtrl.dismiss({edited: true, serverName: this.serverForm.value.name});
    }
  }

  async onSubmit() {

    // Trigger form validation for all fields by marking them as touched
    Object.values(this.serverForm.controls).forEach(control => {
      control.markAsTouched();
    });

    // Check if the form is valid before proceeding with form submission
    if (this.serverForm.valid) {
      const recaptchaToken = await this.recaptchaV3Service.execute('createServer').toPromise();

        this.isLoading = true;
        this.resultHandlerService.showToast({
          message: 'Creando servidor... Por favor, espere unos minutos.',
          color: 'primary'
        });
        const result = await this.apiSdkService.createServer(this.serverForm.value, recaptchaToken as string);
        this.isLoading = false;
        if (result.error) {
          this.resultHandlerService.errorHappened('toast', {
            message: result.error.errors,
            color: 'danger'
          });
          return;

        }
        this.modalCtrl.dismiss({created: true, serverName: this.serverForm.value.name});
    }
  }

  onGoBack() {
    this.modalCtrl.dismiss();
  }
}
