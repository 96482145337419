import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CreateInstanceComponent } from './create-instance/create-instance.component';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { InstanceCardComponent } from './instance-card/instance-card.component';
import { CreateServerComponent } from './create-server/create-server.component';
import { StripeCardComponent } from './stripe-card/stripe-card.component';
import { StripeSubscriptionComponent } from './stripe-subscription/stripe-subscription.component';
import { PopoverMenuComponent } from './popover-menu/popover-menu.component';
import { InstanceColumnsComponent } from './instance-columns/instance-columns.component';
import { InstanceBoxComponent } from './new/instance-box/instance-box.component';
import { ModalPlanUpgradedComponent } from './modal-plan-upgraded/modal-plan-upgraded.component';
import { ModalPhoneExtensionComponent } from './modal-phone-extension/modal-phone-extension.component';
import { ModalCountrySelectorComponent } from './modal-country-selector/modal-country-selector.component';



@NgModule({
  declarations: [
    CreateInstanceComponent,
    InstanceCardComponent,
    CreateServerComponent,
    StripeCardComponent,
    StripeSubscriptionComponent,
    PopoverMenuComponent,
    InstanceColumnsComponent,
    InstanceBoxComponent,
    ModalPlanUpgradedComponent,
    ModalPhoneExtensionComponent,
    ModalCountrySelectorComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module
  ],
  exports: [
    CreateInstanceComponent,
    InstanceCardComponent,
    CreateServerComponent,
    StripeCardComponent,
    StripeSubscriptionComponent,
    PopoverMenuComponent,
    InstanceColumnsComponent,
    InstanceBoxComponent,
    ModalPlanUpgradedComponent,
    ModalPhoneExtensionComponent,
    ModalCountrySelectorComponent
  ]
})
export class ComponentsModule { }
