<ion-content>
    <div class="modal-header-menu">
      <div class="modal-header-menu-backbutton" (click)="onGoBack()">
        <ion-icon name="arrow-back-outline"></ion-icon>
        Volver
      </div>
    </div>
    <br><br>
    <div style="display: flex;align-items: center;">
        <img style="width: 80px;height: 80px;margin-left: 5px;" src="../../../assets/Totalum_Symbol.png">
        <div style="font-size: 20px;padding: 5%;padding-left: 0%;color: rgb(61, 61, 61);">
            <span *ngIf="!edit"> Crear nuevo</span>
            <span *ngIf="edit">Editar</span>
            Servidor
        </div>
    </div>

    <div style="padding:5%;margin-top: -30px;">

        <form [formGroup]="serverForm">

        <ion-list>

            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce el identificador del servidor (ej: mi-servidor)"
                        [clearInput]="true"
                        label="Nombre del servidor (no se podrá cambiar)" label-placement="stacked"
                        formControlName="name"
                        (ionInput)="toLowercase($event)"
                        [disabled]="edit"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="serverForm.get('name')?.invalid && serverForm.get('name')?.touched">
                    <ng-container *ngIf="!serverForm.get('name')?.valid">
                        El nombre del servidor tiene que tener entre 4 y 35 caracteres, todo en minúsculas y no se puede empezar con un número. El único carácter especial habilitado es "-".
                        <br>Ejemplos: miservidor, mi-servidor, mi-servidor-2, mi-servidor-2-3
                    </ng-container>
                </div>
            </div>

            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce la descripción del servidor"
                        [clearInput]="true"
                        label="Descripción" label-placement="stacked"
                        formControlName="description"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="serverForm.get('description')?.invalid && serverForm.get('description')?.touched">
                    <ng-container *ngIf="!serverForm.get('description')?.valid">Es obligatorio introducir una descripción válida de máximo 200 caracteres.</ng-container>
                </div>
            </div>
        </ion-list>

        <br>
        <div *ngIf="!edit && !user?.customPrice" style="font-size: 18px;padding: 5px;border: solid 1px rgb(221, 221, 221);border-radius: 5px;background-color: rgb(247, 247, 247);">
            Al crear el servidor se te cobrará <b>{{pricesServers.v1}}€ + IVA mensuales</b> recurrentes a la tarjeta que tienes vinculada a tu cuenta.
        </div>

        <ion-spinner *ngIf="isLoading" name="lines"></ion-spinner>

        <ion-button *ngIf="!edit" [disabled]="isLoading" mode="ios" class="button-margin-top" (click)="onSubmit()">
            <ion-label>Crear Servidor</ion-label>
        </ion-button>

        <ion-button *ngIf="edit" [disabled]="isLoading" mode="ios" class="button-margin-top" (click)="onEditServer()">
            <ion-label>Editar Servidor</ion-label>
        </ion-button>

        </form>
    </div>

  </ion-content>