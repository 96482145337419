import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiSdkService } from 'src/app/services/api-sdk.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  loginForm!: FormGroup;

  emailToVerify: string = '';
  secretToVerify: string = '';

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private apiSdkService: ApiSdkService,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      this.emailToVerify = params['email'];
      this.secretToVerify = params['secret'];
      if(this.emailToVerify && this.secretToVerify) {
        const result = await this.apiSdkService.login(this.emailToVerify, this.secretToVerify);
        if (result.success?.data) {
          localStorage.setItem('token', result.success.data);
          // TODO: clean url query params
          this.router.navigate(['/account']);
          setTimeout(() => {
            window.location.reload();
          },20);
        }
      }
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get errorControl() {
    return this.loginForm.controls;
  }

  async onSubmit() {


    // Trigger form validation for all fields by marking them as touched
    Object.values(this.loginForm.controls).forEach(control => {
      control.markAsTouched();
    });

    // Check if the form is valid before proceeding with form submission
    if (this.loginForm.valid) {
        const recaptchaToken = await this.recaptchaV3Service.execute('login').toPromise();

        await this.apiSdkService.sendLoginUrl(this.loginForm.value.email, recaptchaToken as string);
        this.router.navigate(['/confirm-email'], {
          queryParams: { email: this.loginForm.value.email }
        });      
    }
  }

}
