import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PopoverHandlerService {

  constructor(
    private alertCtrl: AlertController
  ) { }

  async showConfirmPopover(text: string) {
    const alert = await this.alertCtrl.create({
      header: text,
      cssClass: 'confirmation-alert',
      mode: 'ios',
      buttons: [
        {
          text: 'No',
          cssClass: 'alert-button-cancel',
          role: 'cancel'
        },
        {
          text: 'Si',
          cssClass: 'alert-button-confirm',
          role: 'confirm'
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }

  async showAlertPopover(text: string) {
    const alert = await this.alertCtrl.create({
      header: text,
      cssClass: 'confirmation-alert',
      mode: 'ios',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'alert-button-confirm',
          role: 'confirm'
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }


}
