<ion-content>
    <br><br><br><br><br><br><br>
    <div class="billing-container">
        <div>
            <div *ngIf="user">
                <h2>Servicios contratados</h2>
                <br>
                <h3>Servidores</h3>
                <br>
                <div *ngIf="!user.totalumServers || user.totalumServers?.length === 0">
                    <div style="color: rgb(100, 100, 100);">Aún no tienes ningún servidor creado.</div>
                    <br>
                </div>
                <div *ngFor="let server of user.totalumServers">
                    <div class="billing-server">
                        <div class="plan-profesional">servidor {{server.name}} <span *ngIf="!user.customPrice">- {{pricesServers.v1}}€ + IVA</span></div>
                    </div>
                    <br>
                </div>
                <div *ngFor="let server of user.totalumServers">
                    <div class="billing-instance">
                        <div class="instance-header">
                            <span class="project-title">
                                <div style="font-size: 13px;color: gray;padding:6px;margin-top: 5px;text-align: left;margin-top: -8px;margin-left: -13px;"><span style="background-color: rgba(133, 204, 252, 0.473);padding:5px;border-radius: 15px;">V1</span></div>
                                <div>Servidor {{server.name}}</div>
                                <div style="font-size: 15px;color: gray;margin-top: 5px;">{{server.description}}</div>
                            </span>
                            <span  class="next-payment">Próximo pago: {{calculeNextDayToPay(server.createdAt) | date:'dd/MM/yyyy' }}</span>
                        </div>
                        <div class="pricing-details"><span class="plan-profesional" *ngIf="!user.customPrice">{{pricesServers.v1}}€ + IVA</span></div>
                    </div>
                </div>
                <br>
                <h3>Proyectos</h3>
                <br>
                <div *ngIf="!user.totalumInstances || user.totalumInstances?.length === 0">
                    <div style="color: rgb(100, 100, 100);">Aún no tienes ningún proyecto creado.</div>
                    <br>
                </div>
                <div *ngFor="let instance of user.totalumInstances">
                    <div class="billing-instance">
                        <div class="instance-header">
                            <span class="project-title">
                                <div style="font-size: 13px;color: gray;padding:6px;margin-top: 5px;text-align: left;margin-top: -8px;margin-left: -13px;"><span style="background-color: rgba(133, 204, 252, 0.473);padding:5px;border-radius: 15px;">{{instance.plan}}</span></div>
                                <div>Proyecto {{instance.organizationId}}</div>
                                <div style="font-size: 15px;color: gray;margin-top: 5px;">{{instance.description}}</div>
                            </span>
                            <span *ngIf="instance.plan!=='free'" class="next-payment">Próximo pago: {{calculeNextDayToPay(instance.createdAt) | date:'dd/MM/yyyy' }}</span>
                        </div>
                        <div *ngIf="!user.customPrice" class="pricing-details">
                            <span *ngIf="instance.plan==='free'" class="plan-free">0€</span>
                            <span *ngIf="instance.plan==='shared'" class="plan-profesional">{{pricesIntances.shared}}€ + IVA</span>
                            <span *ngIf="instance.plan==='profesional'" class="plan-profesional">{{pricesIntances.profesional}}€ + IVA</span>
                            <span *ngIf="instance.plan==='enterprise'" class="plan-profesional">{{pricesIntances.enterprise}}€ + IVA</span>
                        </div>
                    </div>
                </div>
                <br><br>
                <div>Todos los servicios se cobran mensualmente</div>
            </div>
            <p class="billing-message">Si tienes algún problema, contáctenos:</p>
            <div class="contact-info">
                <div class="contact-method">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <span>Llama a <a href="tel:+34644479777">+34 644479777</a></span>
                </div>
                <div class="contact-method">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <span>Envíanos un email a <a href="mailto:contacto@speedparadigm.com">{{'contacto@speedparadigm.com'}}</a></span>
                </div>
            </div>
        </div>

    </div>

    <div class="pasarela-pago-container">
        <div class="pasarela-pago">
            <div style="" *ngIf="!userCard">
            <app-stripe-card (uploadCardFinish)="uploadCardFinish()"></app-stripe-card>
            </div>
        
            <br><br>
            
            <div *ngIf="userCard">
            <app-stripe-subscription></app-stripe-subscription>
            </div>
            <br>
        </div>
    </div>
    <br><br><br><br><br><br>

</ion-content>

