import { interval } from 'rxjs';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CreateInstanceComponent } from 'src/app/components/create-instance/create-instance.component';
import { CreateServerComponent } from 'src/app/components/create-server/create-server.component';
import { PlansI, TotalumInstanceI } from 'src/app/interfaces/instance.interface';
import { TotalumServerI } from 'src/app/interfaces/server.interface';
import { UserI } from 'src/app/interfaces/user.interface';
import { ApiSdkService } from 'src/app/services/api-sdk.service';
import { PopoverHandlerService } from 'src/app/services/popover-handler.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';
import { set } from 'lodash';
import { PopoverMenuComponent } from 'src/app/components/popover-menu/popover-menu.component';

@Component({
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss']
})
export class AccountPage {

  userData: UserI= null as any;

  servers: TotalumServerI[] = [];

  freeInstances: TotalumInstanceI[] = [];
  sharedInstances: TotalumInstanceI[] = [];
  profesionalInstances: TotalumInstanceI[] = [];

  serverCreating!: any;

  loading = false;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private apiSdkService: ApiSdkService,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private utilsService:UtilsService,
    private popoverCtrl: PopoverController,
    private popoverHandler: PopoverHandlerService,
    ) { }

  async ngOnInit() {
    await this.getUserData();
  }

  async getUserData() {
    const result = await this.apiSdkService.getFullUserData();
    this.userData = result?.success?.data;
    if (!this.userData?.name) {
      this.router.navigate(['/user-data']);
    }
    this.getInstances();
    await this.getServers();

  }

  async getServers() {
    const servers = this.userData.totalumServers as TotalumServerI[] || [];
    const instances = this.userData.totalumInstances as TotalumInstanceI[] || [];
    this.serverCreating = null as any;
    // we populate the totalumInstancesAssigned of each server
    for (let server of servers) {
      const totalumInstancesPopulated: TotalumInstanceI[] = [];
      for (let instance of instances) {
        if (instance.serverId === server._id) {
          totalumInstancesPopulated.push(instance);
        }
      }
      /*
      const totalumInstancesAssigned = server.totalumInstancesAssigned || [];
      const totalumInstancesPopulated = [];
      if (totalumInstancesAssigned) {
        for (let totalumInstanceId of totalumInstancesAssigned) {
          const totalumInstance = await this.apiSdkService.getInstanceByOrganizationId(totalumInstanceId.organizationId);
          if (totalumInstance.success) {
            totalumInstancesPopulated.push(totalumInstance.success.data);
          }
        }
      }
      */
      server["totalumInstancesPupulated"] = totalumInstancesPopulated;
      if (server.status === 'creating') {
        this.serverCreating = server;
      }
    }
    if (this.serverCreating) {
      this.setServerCreatingInterval(this.serverCreating.name);
    }

    // show only servers with status active
    this.servers = servers.filter(server => server.status === 'active');


  }

  getInstances(){
    this.freeInstances = [];
    this.sharedInstances = [];
    this.profesionalInstances = [];
    if (this.userData.totalumInstances) {
      for (let instance of this.userData.totalumInstances) {
        if(instance.serverId){
          continue;
        }
        switch (instance.plan) {
          case "free":
            this.freeInstances.push(instance);
            break;
          case "shared":
            this.sharedInstances.push(instance);
            break;
          case 'profesional':
            this.profesionalInstances.push(instance);
            break;
          default:
            break;
        }
      }
    }
  }

  async openCreateInstanceModal(options:{plan?:PlansI, serverName?:string}, instance?: TotalumInstanceI) {
    if (this.userData.totalumInstances && this.userData.totalumInstances.length > 50) {
      const result = await this.popoverHandler.showAlertPopover("No puedes tener más de 50 proyectos por cuenta, si necesitas más, contacta con nosotros");
      return;
    }

    if ((this.freeInstances.length > 0 && options.plan === "free") && !instance) {
      if (this.userData.email!=='speedparadigm@gmail.com'){
        const result = await this.popoverHandler.showAlertPopover("Solo se permite tener un proyecto gratuito por cuenta");
        return;
      }
    }
    const modal = await this.modalCtrl.create({
      component: CreateInstanceComponent,
      componentProps: {
        options: options,
        instance: instance,
        instances: this.userData.totalumInstances,
        user: this.userData
      }
    });
    modal.present();

    // we get it's data
    const {data} = await modal.onDidDismiss();
    await this.getUserData();
  }

  async openCreateServerModal(server?: TotalumServerI) {
    if (this.userData.totalumServers && this.userData.totalumServers.length > 20) {
      const result = await this.popoverHandler.showAlertPopover("No puedes tener más de 20 servidores por cuenta, si necesitas más, contacta con nosotros");
      return;
    }

    if (this.serverCreating) {
      const result = await this.popoverHandler.showAlertPopover("Ya hay un servidor con creación en curso, espera a que termine");
      return;
    }

    const modal = await this.modalCtrl.create({
      component: CreateServerComponent,
      componentProps: {
        server: server
      }
    });
    modal.present();

    // we get it's data
    const {data} = await modal.onDidDismiss();
    await this.getUserData();

    // create an interval every 10 seconds to check if the server is active
    if (data.created){
      this.serverCreating = {name: data.serverName, status: 'creating'} as TotalumServerI;
      this.setServerCreatingInterval(data.serverName);
    }
  }

  goToBilling() {
    this.router.navigate(['/billing']);
  }

  async setServerCreatingInterval(serverName: string) {
    let counter = 0;
    const interval = setInterval(async () => {
      const result = await this.apiSdkService.getServerByName(serverName);
      if (result.success) {
        const server = result.success.data;
        if (server.status === 'active') {
          clearInterval(interval);
          await this.getUserData();
        }
      }
      counter++;
      if (counter === 100) {
        clearInterval(interval);
        const result = await this.popoverHandler.showAlertPopover("Ha habido un error creando el servidor, por favor, contacta con nosotros");
      }
    }, 10000);
  }

  async onShowServerPopoverMenu(event: any, server:TotalumServerI) {
    const popover = await this.popoverCtrl.create({
      component: PopoverMenuComponent,
      componentProps: {
        type: 'server'
      },
      event: event
    });
    await popover.present();

    const result = (await popover.onDidDismiss()).data;

    if (result === 'delete') {
      this.loading = true;
      await this.apiSdkService.deleteServerById(server._id as string);
      this.loading = false;
      await this.getUserData();
    }

    if (result === 'edit') {
      this.openCreateServerModal(server);  
    }

    if (result === 'create-instance') {
      this.openCreateInstanceModal({serverName: server.name})
    }
  }

  async onInteractInstance(type: 'edit' | 'delete', instance: TotalumInstanceI,serverName?: string) {
    if (type === 'delete') {
      this.loading = true;
      await this.apiSdkService.deleteInstanceById(instance._id as string);
      this.loading = false;
      await this.getUserData();
    }

    if (type === 'edit') {
      await this.openCreateInstanceModal({plan: instance.plan, serverName: serverName}, instance);
    }
  }

  async openTotalumInstance(instance: TotalumInstanceI) {
    const organizationId = instance.organizationId;
    /*const data = localStorage.getItem(organizationId) as string;
    const decryptedDataString = await this.utilsService.decrypt(data, organizationId);
    //console.log('decryptedDataString',decryptedDataString)
    const result = await this.apiSdkService.crypt(decryptedDataString);
    const cryptedDataString = result.success.data;*/
    const cryptedDataString = instance.defaultUser as string;
    const cryptedDataStringEncoded = encodeURIComponent(cryptedDataString);
    const urlToOpen = `${environment.totalumFrontendUrl}auth/login?auth=${cryptedDataStringEncoded}&organizationId=${organizationId}`;

    window.open(urlToOpen, '_blank');
  }
}
