<ion-content>
    <div style="display: flex; flex-direction: column; height: 100vh; width: 100vw;">
        <div class="ds-accounts-body">
            <div class="ds-accounts-body-box">
                <div class="ds-accounts-body-box-title">
                    Introduce tus datos
                </div>

                <form [formGroup]="userForm" class="ds-accounts-body-box-form">
                    <!-- Email Field -->
                    <div class="ds-input-primary">
                        <div class="ds-input-primary-label">
                            Email
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="email" placeholder="Introduce tu correo electrónico" label="Correo electrónico" label-placement="stacked" formControlName="email">
                            <div *ngIf="errorControl['email']?.invalid && (errorControl['email']?.dirty || errorControl['email']?.touched)" class="error-message">
                                <span *ngIf="errorControl['email']?.errors?.['required']">El Email es obligatorio.</span>
                                <span *ngIf="errorControl['email']?.errors?.['email']">Introduce un Email válido.</span>
                            </div>
                        </div>
                    </div>

                    <!-- Name Field -->
                    <div class="ds-input-primary">
                        <div class="ds-input-primary-label">
                            Nombre completo
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="text" placeholder="Introduce tu nombre completo (nombre y apellidos)" formControlName="name">
                            <div *ngIf="errorControl['name']?.invalid && (errorControl['name']?.dirty || errorControl['name']?.touched)" class="error-message">
                                <span *ngIf="errorControl['name']?.errors?.['required']">El nombre es obligatorio.</span>
                                <span *ngIf="errorControl['name']?.errors?.['minlength'] || errorControl['name']?.errors?.['maxlength']">Longitud de nombre no válida.</span>
                            </div>
                        </div>
                    </div>

                    <!-- Add similar error message blocks for the remaining fields -->
                    <!-- For demonstration, I'll add it for the phone field as well -->

                    <!-- Phone Field -->
                    <div class="ds-input-primary">
                        <div class="ds-input-primary-label">
                            Teléfono
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="text" placeholder="Introduce tu teléfono" formControlName="phone">
                            <div *ngIf="errorControl['phone']?.invalid && (errorControl['phone']?.dirty || errorControl['phone']?.touched)" class="error-message">
                                <span *ngIf="errorControl['phone']?.errors?.['required']">El teléfono es obligatorio.</span>
                                <span *ngIf="errorControl['phone']?.errors?.['pattern']">Formato de teléfono no válido.</span>
                                <span *ngIf="errorControl['phone']?.errors?.['minlength'] || errorControl['phone']?.errors?.['maxlength']">Longitud de teléfono no válida.</span>
                            </div>
                        </div>
                    </div>

                    <!-- NIF/CIF Field -->
                    <div class="ds-input-primary">
                        <div class="ds-input-primary-label">
                            NIF o CIF
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="text" placeholder="Introduce el nif/cif propio o de tu empresa" formControlName="nif">
                            <div *ngIf="errorControl['nif']?.invalid && (errorControl['nif']?.dirty || errorControl['nif']?.touched)" class="error-message">
                                <span *ngIf="errorControl['nif']?.errors?.['required']">El NIF o CIF es obligatorio.</span>
                                <span *ngIf="errorControl['nif']?.errors?.['minlength'] || errorControl['nif']?.errors?.['maxlength']">Longitud del NIF/CIF no válida.</span>
                            </div>
                        </div>
                    </div>

                    <!-- Company Name Field -->
                    <div class="ds-input-primary">
                        <div class="ds-input-primary-label">
                            Nombre de empresa o particular
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="text" placeholder="Introduce el nombre de tu empresa" formControlName="companyName">
                            <div *ngIf="errorControl['companyName']?.invalid && (errorControl['companyName']?.dirty || errorControl['companyName']?.touched)" class="error-message">
                                <span *ngIf="errorControl['companyName']?.errors?.['required']">El nombre de la empresa es obligatorio.</span>
                                <span *ngIf="errorControl['companyName']?.errors?.['minlength'] || errorControl['companyName']?.errors?.['maxlength']">Longitud del nombre de la empresa no válida.</span>
                            </div>
                        </div>
                    </div>

                    <!-- Country Field -->
                    <div class="ds-input-primary" #triggerInput>
                        <div class="ds-input-primary-label">
                            País
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="text" placeholder="Introduce tu país" formControlName="country" (click)="onExtensionSelectorClick($event)" (input)="setSearchTerm($event)" id="countryInput">
                            <div *ngIf="errorControl['country']?.invalid && (errorControl['country']?.dirty || errorControl['country']?.touched)" class="error-message">
                                <span *ngIf="errorControl['address']?.errors?.['required']">El país es obligatorio. <br></span>
                                <span *ngIf="errorControl['country']?.errors?.['minlength'] || errorControl['country']?.errors?.['maxlength']">Longitud del país no válida. <br></span>
                                <span *ngIf="errorControl['country']?.errors?.['invalidCountryName']">Debes introducir o seleccionar un país de la ventana de países <br></span>
                            </div>
                        </div>
                    </div>

                    <!-- City Field -->
                    <div class="ds-input-primary">
                        <div class="ds-input-primary-label">
                            Ciudad
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="text" placeholder="Introduce tu ciudad" formControlName="city">
                            <div *ngIf="errorControl['city']?.invalid && (errorControl['city']?.dirty || errorControl['city']?.touched)" class="error-message">
                                <span *ngIf="errorControl['address']?.errors?.['required']">La ciudad es obligatoria.</span>
                                <span *ngIf="errorControl['city']?.errors?.['minlength'] || errorControl['city']?.errors?.['maxlength']">Longitud de la ciudad no válida.</span>
                            </div>
                        </div>
                    </div>

                    <div class="ds-input-primary">
                        <div class="ds-input-primary-label">
                            Código postal
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="text" placeholder="Introduce tu Código Postal" formControlName="cp">
                            <div *ngIf="errorControl['cp']?.invalid && (errorControl['cp']?.dirty || errorControl['cp']?.touched)" class="error-message">
                                <span *ngIf="errorControl['cp']?.errors?.['required']">El código Postal no es válido.</span>
                                <span *ngIf="errorControl['cp']?.errors?.['minlength'] || errorControl['cp']?.errors?.['maxlength']">Código Postal no válido</span>
                            </div>
                        </div>
                    </div>

                    <!-- Address Field -->
                    <div class="ds-input-primary">
                        <div class="ds-input-primary-label">
                            Dirección
                        </div>
                        <div class="ds-input-primary-content">
                            <input type="text" placeholder="Introduce tu dirección" formControlName="address">
                            <div *ngIf="errorControl['address']?.invalid && (errorControl['address']?.dirty || errorControl['address']?.touched)" class="error-message">
                                <span *ngIf="errorControl['address']?.errors?.['required']">La dirección es obligatoria.</span>
                                <span *ngIf="errorControl['address']?.errors?.['minlength'] || errorControl['address']?.errors?.['maxlength']">Longitud de la dirección no válida.</span>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="ds-accounts-body-box-form-button">
                        <button class="ds-button-secondary" type="submit" (click)="logout()">Cerrar sesión</button>

                        <button class="ds-button-primary" type="submit" [disabled]="!this.userForm.valid" (click)="onSubmit()">Guardar datos</button>
                    </div>
                </form>
            </div>

            <div class="ds-accounts-body-box ds-accounts-body-payment" *ngIf="userData?.totalumInstances?.length! > 0">
        
                <div class="ds-accounts-body-box-title">
                    Tus planes
                </div>

                <br>

                <div class="ds-accounts-plan" *ngFor="let instance of userData?.totalumInstances">
                    <div class="ds-accounts-plan-header">
                        <div class="ds-accounts-plan-header-left">
                            <div class="ds-accounts-plan-header-left-totalumlogo">
                                <img src="assets/totalum-logo-recortado.png" alt="Logo">
                            </div>
                            <div class="ds-accounts-plan-header-left-totalumname">
                                {{instance.organizationId}}
                            </div>
                        </div>
                        <div class="ds-accounts-plan-header-right">
                            <div class="ds-chip">Plan&nbsp;<span class="ds-chip-plan"> {{instance?.plan}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="ds-accounts-plan-content">
                        <div class="ds-block-propertyandvalue-horizontal">
                            <div class="ds-block-propertyandvalue-horizontal-property">
                                Precio mensual
                            </div>
                            <div class="ds-block-propertyandvalue-horizontal-value">
                                <span *ngIf="instance.plan==='free'" class="plan-free">0€</span>
                                <span *ngIf="instance.plan==='shared'" class="plan-profesional">{{pricesIntances.shared}}€ + IVA</span>
                                <span *ngIf="instance.plan==='profesional'" class="plan-profesional">{{pricesIntances.profesional}}€ + IVA</span>
                                <span *ngIf="instance.plan==='enterprise'" class="plan-profesional">{{pricesIntances.enterprise}}€ + IVA</span>
                            </div>
                        </div>
                        <div class="ds-block-propertyandvalue-horizontal">
                            <div class="ds-block-propertyandvalue-horizontal-property">
                                Próximo pago
                            </div>
                            <div class="ds-block-propertyandvalue-horizontal-value">
                                {{calculeNextDayToPay(instance.createdAt) | date:'dd/MM/yyyy' }}
                            </div>
                        </div>
                        <div class="ds-block-propertyandvalue-horizontal">
                            <div class="ds-block-propertyandvalue-horizontal-property">
                                Cancelar plan
                            </div>
                            <div class="ds-block-propertyandvalue-horizontal-value" style="color: rgb(209, 0, 0); text-decoration: underline; cursor: pointer;">
                                Cancelar
                            </div>
                        </div>
                    </div>
                    
                </div>
                <br>

                <ng-container  *ngIf="!userCard">
                    <div class="ds-accounts-body-box-title">
                      Método de pago
                    </div>

                    <div class="ds-accounts-body-box-subtitle">
                        Al registrar la tarjeta, se te cobrará 1€ para verificarla y se te devolverá.
                    </div>
            
                    <app-stripe-card [noText]="true" (uploadCardFinish)="uploadCardFinish()"></app-stripe-card>
                </ng-container>
                
                <ng-container *ngIf="userCard">
                    <div class="ds-accounts-body-box-title">
                        Tu tarjeta
                    </div>
                  <app-stripe-subscription [noText]="true"></app-stripe-subscription>
                </ng-container>
        
            </div>
        </div>
    </div>
</ion-content>

<div #tooltip class="custom-tooltip" id="customTooltip" *ngIf="tooltipVisible" [ngStyle]="{'top': tooltipPosition.top, 'left': tooltipPosition.left, 'width': tooltipPosition.width}">
  <app-modal-country-selector (onSelect)="onSelectCountry($event)" [searchTerm]="searchTerm"></app-modal-country-selector>
</div>
  
  




<!--<ion-content [fullscreen]="true" class="ion-padding">

    <div style="padding:5%;padding-left:15%;padding-right:15%">

        <form [formGroup]="userForm">

        <ion-list>

            <div>
                <b>Introduce tus datos</b>

                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="email"
                        placeholder="Introduce tu correo electrónico"
                        [clearInput]="true"
                        label="Correo electrónico" label-placement="stacked"
                        formControlName="email"
                    ></ion-input>
                </ion-item>
            </div>
            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce tu nombre completo (nombre y apellidos)"
                        [clearInput]="true"
                        label="Nombre completo" label-placement="stacked"
                        formControlName="name"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched">
                    <ng-container *ngIf="!userForm.get('name')?.valid">Es obligatorio introducir un nombre válido.</ng-container>
                </div>
            </div>
            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce tu teléfono"
                        [clearInput]="true"
                        label="teléfono" label-placement="stacked"
                        formControlName="phone"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="userForm.get('phone')?.invalid && userForm.get('phone')?.touched">
                    <ng-container *ngIf="!userForm.get('phone')?.valid">Es obligatorio introducir un teléfono válido.</ng-container>
                </div>
            </div>
            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce el nif/cif propio o de tu empresa"
                        [clearInput]="true"
                        label="Nif o Cif (opcional)" label-placement="stacked"
                        formControlName="nif"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="userForm.get('nif')?.invalid && userForm.get('nif')?.touched">
                    <ng-container *ngIf="!userForm.get('nif')?.valid">nif/cif demasiado corto o largo</ng-container>
                </div>
            </div>
            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce el nombre de tu empresa"
                        [clearInput]="true"
                        label="Nombre de empresa (opcional)" label-placement="stacked"
                        formControlName="companyName"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="userForm.get('companyName')?.invalid && userForm.get('companyName')?.touched">
                    <ng-container *ngIf="!userForm.get('companyName')?.valid">nombre demasiado corto o largo</ng-container>
                </div>
            </div>
            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce tu país"
                        [clearInput]="true"
                        label="País (opcional)" label-placement="stacked"
                        formControlName="country"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="userForm.get('country')?.invalid && userForm.get('country')?.touched">
                    <ng-container *ngIf="!userForm.get('country')?.valid">El país introducido no es válido.</ng-container>
                </div>
            </div>
            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce tu ciudad"
                        [clearInput]="true"
                        label="Ciudad (opcional)" label-placement="stacked"
                        formControlName="city"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="userForm.get('city')?.invalid && userForm.get('city')?.touched">
                    <ng-container *ngIf="!userForm.get('city')?.valid">La ciudad introducida no es válida.</ng-container>
                </div>
            </div>
            <div>
                <ion-item lines="full" class="form-inputs-margin">
                    <ion-input 
                        type="text"
                        placeholder="Introduce tu dirección"
                        [clearInput]="true"
                        label="Dirección (opcional)" label-placement="stacked"
                        formControlName="address"
                    ></ion-input>
                </ion-item>
                <div class="form-input-error" color="danger" *ngIf="userForm.get('address')?.invalid && userForm.get('address')?.touched">
                    <ng-container *ngIf="!userForm.get('address')?.valid">Es obligatorio introducir una dirección válida.</ng-container>
                </div>
            </div>
        </ion-list>
        
        <ion-button mode="ios" class="button-margin-top" (click)="onSubmit()">
            <ion-label>Guardar</ion-label>
        </ion-button>

        </form>
        <br><br><br><br>
        <a (click)="logout()" style="cursor: pointer;margin-top: 40px;">
            Cerrar sesión
        </a>

    </div>

</ion-content>
  -->