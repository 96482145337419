import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
  ) { }

  async encrypt(data:string, secret:string) {
    return CryptoJS.AES.encrypt(data, secret).toString();

  }

  async decrypt(data: string, secret:string): Promise<string>  {
    const decryptedBytes = CryptoJS.AES.decrypt(data, secret);
    const u = decryptedBytes.toString(/*CryptoJS.enc.Utf8*/);
    const t = this.hexToString(u);
    return t;
  }



  hexToString(hex: string): string {
    const bytes = new Uint8Array(hex.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
    return new TextDecoder().decode(bytes);
  }

}