import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountPage } from './pages/account/account.page';
import { LoginPage } from './pages/login/login.page';
import { ConfirmEmailPage } from './pages/confirm-email/confirm-email.page';
import { UserDataPage } from './pages/user-data/user-data.page';
import { BillingPage } from './pages/billing/billing.page';

const routes: Routes = [

  {
    path: 'login',
    loadChildren: () => import('./pages/onboarding-flow/onboarding-flow.module').then( m => m.OnboardingFlowPageModule)
  },
  {
    path: '',
    redirectTo: 'account',
    pathMatch:'full'
  },
  {
    path: 'old-login',
    component: LoginPage
  },
  {
    path: 'account',
    component: AccountPage
  },
  {
    path: 'confirm-email',
    component: ConfirmEmailPage
  },
  {
    path: 'user-data',
    component: UserDataPage
  },
  {
    path: 'billing',
    component: BillingPage
  },
  {
    path: 'select-plan',
    loadChildren: () => import('./pages/plans/select-plan/select-plan.module').then( m => m.SelectPlanPageModule)
  },
  {
    path: 'plan-selected',
    loadChildren: () => import('./pages/plans/plan-selected/plan-selected.module').then( m => m.PlanSelectedPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
