import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

export interface MixpanelTrack {
  type: 'visit_page' | 'click' | 'question' | 'navigation';
  place: string;
  extraText?: string;
  action?: {
    answer?: any;
  } | any
}

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  constructor() { }

  init(userToken: string): void {
    try {
      mixpanel.init(userToken);
    } catch (err) {

    }
  }

  createTracking(track: MixpanelTrack) {
    try {
      let trackTypeText = '';

      switch (track.type) {
        case 'visit_page':
          trackTypeText = 'Visit page';
          break;
        case 'click':
          trackTypeText = 'Click';
          break;
        case 'question':
          trackTypeText = 'Question';
          break;
        case 'navigation':
          trackTypeText = 'Goes to';
          break;
      }
  
      trackTypeText = `${track.place} - ${trackTypeText}`;
  
      if (track.extraText) {
        trackTypeText += ` - ${track.extraText}`;
      }
  
      this.track(trackTypeText, track.action);
    } catch (err) {
      //console.error('Error creating mixpanel tracking', err);
    }
    
  }

  private track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}
