<div *ngIf="type==='instance'">
  <ion-list >
    <ion-item button [detail]="false" (click)="onEdit()">
      <span style="margin-left: 20px;"><i class="fa-regular fa-pen-to-square"></i> Editar proyecto</span>
    </ion-item>
    <ion-item button [detail]="false" lines="none"  (click)="onDelete()">
      <span style="margin-left: 20px;"><i class="fa-regular fa-trash-can"></i> Borrar proyecto</span>
    </ion-item>
  </ion-list>
</div>

<div *ngIf="type==='server'">
  <ion-list >
    <ion-item button [detail]="false" (click)="onEdit()">
      <span style="margin-left: 20px;"><i class="fa-regular fa-pen-to-square"></i> Editar servidor</span>
    </ion-item>
    <ion-item button [detail]="false" lines="none"  (click)="onDelete()">
      <span style="margin-left: 20px;"><i class="fa-regular fa-trash-can"></i> Borrar servidor</span>
    </ion-item>
  </ion-list>
</div>
