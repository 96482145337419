<div class="b-instance">
  <div class="b-instance-header">
      <div class="b-instance-name">
          {{instance.organizationId}}
      </div>
      <div class="b-instance-img">
          <img src="../assets/Totalum_Symbol.png" alt="">
      </div>
  </div>

  <div class="b-instance-infos">
      <div class="b-instance-info">
          <div class="b-instance-info-title">PLAN</div>
          <div class="b-instance-info-value">
              {{instance.plan}}
          </div>
      </div>

      <div class="b-instance-info">
          <div class="b-instance-info-title">DESCRIPCIÓN</div>
          <div class="b-instance-info-value">
              <ng-container *ngIf="instance?.description">
                  {{instance?.description}}
              </ng-container>
              <ng-container *ngIf="!instance?.description || instance?.description === ''">
                  Sin descripción
              </ng-container>
          </div>
      </div>
  </div>

  <div class="b-instance-createdat">
      Creado el {{instance?.createdAt | date: 'dd/MM/yyyy'}}
  </div>

  <div class="b-instance-buttons">
      <ion-button mode="ios" class="button-primary"  (click)="openTotalumInstance(instance)">
          <ion-icon slot="start" name="log-in-outline"></ion-icon>
          Acceder
      </ion-button>
      
      <ion-button mode="ios" class="button-secondary"  (click)="onShowPopoverMenu($event, 'edit')">
          <ion-icon slot="start" name="create-outline"></ion-icon>
          Editar
      </ion-button>
  </div>
</div>