import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './confirm-email.page.html',
  styleUrls: ['./confirm-email.page.scss']
})
export class ConfirmEmailPage {

  emailToVerify: string = '';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        this.emailToVerify = params['email'];  // Replace 'paramName' with your specific parameter name
    });
  }

}
