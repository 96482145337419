<ion-content style="max-height: 350px;">
  <div class="modal-content">
  
    <div class="modal-content-results">
      <ion-list>
        <ion-item *ngFor="let phone of displayedPhoneExtensions" [button]="true" (click)="onSelectPhone(phone)">
          <ion-label>
            <h2><span [class]="'fi fi-' + phone.code_2.toLowerCase()"></span> {{ phone.name_es }}</h2>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll threshold="30px" (ionInfinite)="loadMore($event)">
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          loadingText="Loading more...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>
  </div>
</ion-content>