import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export interface SpHttpServiceResponse {
  success: any,
  error: any
}

export interface SpHttpServiceResponseError {
  code: any;
  extras?: any;
}

@Injectable({
  providedIn: 'root'
})
export class SpHttpCommonService {

  constructor(
    private httpClient:HttpClient,
    private router: Router,
) { }


  async httpGet(endpointUrl: string, params: HttpParams, noRedirect?: boolean) {
    /*const params = new HttpParams()
      .set('page', PageNo)
      .set('sort', SortOn);*/
    const headers = this.getAppHeaders();
    let result = {} as SpHttpServiceResponse;
    try {
      result['success'] = await this.httpClient.get(endpointUrl, {headers, params}).toPromise();
    } catch(error: any){
      if(error.status === 401 && !noRedirect){
        this.router.navigateByUrl('/login');
      }
      result['error'] = error.error;
    }
    return result;
  }

  async httpPost(endpointUrl: string, body: any, noRedirect?: boolean): Promise<SpHttpServiceResponse>{
    const headers = this.getAppHeaders();
    let result = {} as SpHttpServiceResponse;

    try {
      result['success'] = await this.httpClient.post(endpointUrl, body, {headers:headers}).toPromise();
    } catch(error: any){
      result['error'] = error.error;
      console.error(error);
      if(error.status === 401){
        //this.router.navigateByUrl('/auth/login');
      }
    }
    return result;
  }

  async httpPut(endpointUrl: string, body: any){
    const headers = this.getAppHeaders();
    let result = {} as SpHttpServiceResponse;
    try {
      result['success'] = await this.httpClient.put(endpointUrl, body, {headers:headers}).toPromise();
    } catch(error: any){
      result['error'] = error;
      console.error(error);
      if(error.status === 401){
        //this.router.navigateByUrl('/auth/login');
      }

    }
    return result;
  }

  async httpPatch(endpointUrl: string, body: any){
    const headers = this.getAppHeaders();
    let result = {} as SpHttpServiceResponse;
    try {
      result['success'] = await this.httpClient.patch(endpointUrl, body, {headers:headers}).toPromise();
    } catch(error: any){
      result['error'] = error;
      console.error(error);
      if(error.status === 401){
        //this.router.navigateByUrl('/auth/login');
      }

    }
    return result;
  }

  async httpDelete(endpointUrl: string){
    const headers = this.getAppHeaders();
    let result = {} as SpHttpServiceResponse;
    try {
      result['success'] = await this.httpClient.delete(endpointUrl, {headers:headers}).toPromise();
    } catch(error: any){
      result['error'] = error;
      console.error(error);
      if(error.status === 401){
        //this.router.navigateByUrl('/auth/login');
      }
    }
    return result;
  }

  private getAppHeaders(): HttpHeaders {
    const token = this.getToken();
    const httpHeaders = new HttpHeaders({
      //'Content-Type':  'application/json',
      Authorization: token ? 'Bearer '+token : ''
    });
    return httpHeaders;
  }

  private getToken() {
    let token = null;
    try {
      // TODO: REEMPLAÇAR EL LOCAL STORAGE PER UN PLUGIN DE CORDOVA
      token = localStorage.getItem('token');
    } catch (err) {}
    return token;
  }
}
