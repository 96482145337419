import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { instancesPrice } from 'src/app/constants/pricing';
import { PlansI, TotalumInstanceI, TotalumInstanceLimitI } from 'src/app/interfaces/instance.interface';
import { UserI } from 'src/app/interfaces/user.interface';
import { ApiSdkService } from 'src/app/services/api-sdk.service';
import { ResultHandlerService } from 'src/app/services/result-handler.service';
import { UtilsService } from 'src/app/services/utils.service';

export interface UsageStatisticsI {
  _id?: string;
  organizationId: string;
  year: number;
  month: number;
  usage: {[usageType: string]: number},
  days: {
      day: number;
      usage: {[usageType: string]: number},
      hours: {
          hour: number;
          usage: {[usageType: string]: number},
          minutes: {
              minute: number;
              usage: {[usageType: string]: number},
          }[]
      }[]
  }[]
}

@Component({
  selector: 'app-create-instance',
  templateUrl: './create-instance.component.html',
  styleUrls: ['./create-instance.component.scss']
})
export class CreateInstanceComponent {

  @Input() options!: {plan?:PlansI, serverName?:string}

  @Input() instance?: TotalumInstanceI;

  @Input() instances!: TotalumInstanceI[];

  @Input() user!: UserI

  instancesAvailableForMoveFiles: TotalumInstanceI[] = [];

  organizationIdSelectedForMoveFiles = '';

  edit = false;

  showUpdatePlan = false;

  instanceForm!: FormGroup;

  priceToPay = 0;

  isLoading = false;

  plansAvailable: PlansI[] = [];

  instanceLimits: any;

  addCreditsAvailable = [{name:'documentScanned', label: 'Scan Units', value: 0, newValue: 0}];

  historicalCreditPayments: TotalumInstanceLimitI["historical_payments"] = [];

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private apiSdkService: ApiSdkService,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private resultHandlerService: ResultHandlerService,
    private utilsService:UtilsService,
    private recaptchaV3Service: ReCaptchaV3Service,

    ) { }

    

  async ngOnInit() {
    this.getInstancesAvailableForMoveFiles();
    this.instanceForm = this.formBuilder.group({
      organizationId: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(35), Validators.pattern('^[a-z]([a-z0-9]|-(?!-))*(?<!-)$')]],
      description: ['', [Validators.maxLength(200)]],
      plan: ['', [Validators.required]],
    });
    switch (this.options.plan) {
      case 'free':
        this.plansAvailable = ['free', 'shared', 'profesional'];
        break;
      case 'shared':
        this.plansAvailable = ['shared', 'profesional'];
        break;
      case 'profesional':
        this.plansAvailable = ['profesional'];
        break;
      default:
        this.plansAvailable = ['free','shared','profesional', 'enterprise'];
        break;
    }
    if (this.instance) {
      console.log("this.instance",this.instance);
      this.instanceForm.patchValue(this.instance);
      this.edit = true;
      this.instanceForm.controls['organizationId'].disable();
      console.log("this.edit",this.edit);
      return;
    }
    // add sleep of 100 ms
    await new Promise(resolve => setTimeout(resolve, 100));
    this.instanceForm.patchValue({
      plan: this.options.plan
    });
    this.calculePrice();
  }

  toLowercase(event: any) {
    let inputValue = event.target.value;
    if (inputValue && typeof inputValue === 'string') {
      inputValue = inputValue.toLowerCase().replace(/\s+/g, '-');


      this.instanceForm.get('organizationId')!.setValue(inputValue.toLowerCase());
    }
  }

  getInstancesAvailableForMoveFiles() {
    this.instancesAvailableForMoveFiles = [];
    for (let instance of this.instances) {
      if (instance.plan !== 'free' && instance._id !== this.instance?._id) {
        this.instancesAvailableForMoveFiles.push(instance);
      }
    }
  }

  async updatePlan() {
    if (!this.instance) return;
    this.isLoading = true;
    const recaptchaToken = await this.recaptchaV3Service.execute('login').toPromise();
    const newPlan=this.instanceForm.value.plan;
    const result = await this.apiSdkService.changeInstancePlan(this.instance.organizationId!, newPlan, recaptchaToken!);
    if (result.success) {
      this.resultHandlerService.showToast({
        message: 'Plan actualizado correctamente',
        color: 'success'
      });
    } else {
      this.resultHandlerService.errorHappened('toast', {
        message: 'Error al actualizar el plan',
        color: 'danger'
      });
    }
    this.isLoading = false;
  }

  async moveFilesToAnotherInstance(organizationIdSelectedForMoveFiles: string){
    this.isLoading = true;
    const recaptchaToken = await this.recaptchaV3Service.execute('moveInstanceFilesToAnother').toPromise();

    const result = await this.apiSdkService.moveInstanceFilesToAnother(this.instance?.organizationId as string, organizationIdSelectedForMoveFiles, recaptchaToken as string);
    if(result.success){
      this.resultHandlerService.showToast({
        message: 'Archivos movidos correctamente',
        color: 'success'
      });
    } else {
      console.log("result.error",result.error);
      this.resultHandlerService.errorHappened('toast', {
        message: 'Error al mover los archivos',
        color: 'danger'
      });
    }
    this.isLoading = false;
    this.organizationIdSelectedForMoveFiles = '';


  }

  get errorControl() {
    return this.instanceForm.controls;
  }

  async addCreditToInstance(usageType:string, credit: number) {
    this.isLoading = true;
    const result = await this.apiSdkService.addCredits(this.instance?.organizationId as string, credit, usageType);
    if (result.success) {
      for (let credit of this.addCreditsAvailable) {
        credit.newValue = 0;
      }
      this.resultHandlerService.showToast({
        message: 'Créditos añadidos correctamente',
        color: 'success'
      });
    } else {
      this.resultHandlerService.errorHappened('toast', {
        message: 'Error al añadir créditos',
        color: 'danger'
      });
    }
    this.isLoading = false;
    this.getInstanceCredits();
  }

  async getInstanceCredits(){
    // call to api for get credits of the instance with organizationId
    const result = await this.apiSdkService.getInstanceCredits(this.instance?.organizationId as string);
    console.log("result",result);
    const instanceLimits: TotalumInstanceLimitI[] = result.success.data as TotalumInstanceLimitI[];
    this.instanceLimits = instanceLimits;
    //find the limit of the instance that is documentScanned
    const instanceLimitsDocumentScanned = instanceLimits.find(limit => limit.usage_type === 'documentScanned');
    try {
      // fill addCreditsAvailable with the limits of the instance
      this.addCreditsAvailable[0] = {name:'documentScanned', label: 'Scan Units', value: instanceLimitsDocumentScanned?.credits as number, newValue: 0};
      
      //get the usage of the instance
      const resultUsage = await this.apiSdkService.getInstanceUsage(this.instance?.organizationId as string);
      const instanceUsage: UsageStatisticsI[] = resultUsage.success.data;
      console.log("instanceUsage",instanceUsage);

      // calculate final credit of the instance (taking the instanceLimitsDocumentScanned.credits - instanceUsage[0].usage.documentScanned)
      const instanceUsageDocumentScanned = instanceUsage[0].usage["documentScanned"];
      console.log("instanceUsageDocumentScanned",instanceUsageDocumentScanned);
      const instanceCreditsDocumentScanned = instanceLimitsDocumentScanned?.credits as number;

      console.log("instanceCreditsDocumentScanned",instanceCreditsDocumentScanned);
      const pricePerUnit = instanceLimitsDocumentScanned?.price_per_unit as number;
      const creditsConsumed = instanceUsageDocumentScanned * pricePerUnit;
      const finalCreditsDocumentScanned = instanceCreditsDocumentScanned - creditsConsumed;
      this.addCreditsAvailable[0].value = finalCreditsDocumentScanned;


      // get historical add credits of the instance
      const historicalPayments = instanceLimitsDocumentScanned?.historical_payments;
      console.log("historicalPayments",historicalPayments);

      this.historicalCreditPayments = historicalPayments || [];
    } catch (e) {

    }

  }

  openHistoricCreditsModal(historicalCreditPayments: TotalumInstanceLimitI["historical_payments"]) {
    console.log("historicalCreditPayments",historicalCreditPayments);
  }

  async getUserData() {
    const result = await this.apiSdkService.getFullUserData();
    const userData = result.success.data;
    this.instanceForm.patchValue(userData);
  }

  async onEdit() {
    // Trigger form validation for all fields by marking them as touched
    Object.values(this.instanceForm.controls).forEach(control => {
      control.markAsTouched();
    });

    if (this.instanceForm.valid) {
      const recaptchaToken = await this.recaptchaV3Service.execute('editInstance').toPromise();

        this.isLoading = true;
        this.resultHandlerService.showToast({
          message: 'Editando proyecto... Por favor, espere unos segundos.',
          color: 'primary'
        });
        let result;

        this.instanceForm.value["_id"] = this.instance?._id;
        console.log("this.instanceForm.value",this.instanceForm.value);
        result = await this.apiSdkService.editInstance(this.instanceForm.value, recaptchaToken as string, this.options.serverName);

        this.isLoading = false;
        if (result.error) {
          this.resultHandlerService.errorHappened('toast', {
            message: result.error.errors,
            color: 'danger'
          });
          return;

        }
        this.resultHandlerService.showToast({
          message: 'Proyecto Editado correctamente',
          color: 'success'
        });
        this.modalCtrl.dismiss();
    }
  }

  async onSubmit() {

    // Trigger form validation for all fields by marking them as touched
    Object.values(this.instanceForm.controls).forEach(control => {
      control.markAsTouched();
    });

    // Check if the form is valid before proceeding with form submission
    if (this.instanceForm.valid) {
      const recaptchaToken = await this.recaptchaV3Service.execute('createInstance').toPromise();

        this.isLoading = true;
        this.resultHandlerService.showToast({
          message: 'Creando proyecto... Por favor, espere unos segundos.',
          color: 'primary'
        });
        const result = await this.apiSdkService.createInstance(this.instanceForm.value, recaptchaToken as string, this.options.serverName);
        this.isLoading = false;
        if (result.error) {
          this.resultHandlerService.errorHappened('toast', {
            message: result.error.errors,
            color: 'danger'
          });
          return;

        }
        /*const defaultAdminUser = result.success?.data;
        console.log("eeeeeeeeeeeeeeeeee",defaultAdminUser);
        const defaultAdminUserString = JSON.stringify(defaultAdminUser);
        const defaultAdminUserStringEncrypted = await this.utilsService.encrypt(defaultAdminUserString, this.instanceForm.value.organizationId);
        if (defaultAdminUser) {
          localStorage.setItem(this.instanceForm.value.organizationId, defaultAdminUserStringEncrypted);
        }*/
        this.resultHandlerService.showToast({
          message: 'Proyecto creado correctamente',
          color: 'success'
        });
        this.modalCtrl.dismiss();
    }
  }

  calculePrice(){
    if (this.user.customPrice){ 
      return;
    }
    console.log("this.instanceForm.value.plan",this.instanceForm.value.plan);
    if (this.instanceForm.value.plan !== this.instance?.plan && this.instance?.plan) {
      this.showUpdatePlan = true;
    } else {
      this.showUpdatePlan = false;
    }
    this.priceToPay = 0;
    switch (this.instanceForm.value.plan) {
      case 'free':
        this.priceToPay = 0;
        break;
      case 'shared':
        this.priceToPay = instancesPrice.shared;
        break;
      case 'profesional':
        this.priceToPay = instancesPrice.profesional;
        break;
      case 'enterprise':
        this.priceToPay = instancesPrice.enterprise;
        break;
      default:
        this.priceToPay = 0;
        break;
    }
  }

  onGoBack() {
    this.modalCtrl.dismiss();
  }
}
